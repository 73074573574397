import React from 'react';
import './BlogNaviagationButton.scss';
import {useNavigate} from "react-router-dom";

interface IProps {
    title: string;
    blogId: number | null;
}

export const BlogNavigationButton: React.FC<IProps> = ({title, blogId}) => {
    const navigate = useNavigate()
    const navigateHandler = () => {
        if (!!blogId) {
            navigate(`/blog/${blogId}`)
        }
    }
    return (
        <button
            onClick={navigateHandler}
            className={`blogNavigationButton ${!!blogId ? '' : 'blogNavigationButton__disable'}`}
            type="submit"
        >
            {title}
        </button>
    )
}