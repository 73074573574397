import {useApiCall} from "./useApiCalls";
import {useBlogStore} from "../store/hooks";
import {useCallback} from "react";
import {IBlogCard, ISingleBlog} from "../interfaces";

export const useBlogApi = () => {
    const {api} = useApiCall();
    const blogStore = useBlogStore();

    const getBlogList = useCallback(async () => {
        const res: IBlogCard[] = await api.apiInstance.get('blogs')
        blogStore.setBlogList(res)
    }, [api.apiInstance, blogStore])

    const getSingleBlogInfo = useCallback(async (blogId: number) => {
        const res: ISingleBlog = await api.apiInstance.get(`blog/${blogId}`)
        blogStore.setSingleBlogInfo(res)
    }, [api.apiInstance, blogStore])

    return {
        getBlogList,
        getSingleBlogInfo,
    }
}