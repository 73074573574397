import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import {BlockTitle} from "../../../shared/BlockTitle/BlockTitle";
import {MainPageLinkButton} from "../../../shared/buttons/mainPageLinkButton/MainPageLinkButton";
import {useProductStore} from "../../../../store/hooks";
import {IProductInfo} from "../../../../interfaces";
import {ProductCard} from "../../../shared/cards/productCard/ProductCard";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './ProductsBlock.scss';
import {observer} from "mobx-react-lite";

export const ProductsBlock = observer(() => {
    const productStore = useProductStore();

    const sliderProductList = productStore.sliderProductList
    const isSliderProductListNoEmpty = sliderProductList.length !== 0

    return (
        <>
        <div className={'productsBlock'}>
            <div className={'app__container'}>
                <BlockTitle title={'Produkty'}/>
                <div className={'productsBlock__cardsArea'}>
                    <Swiper
                        breakpoints={{


                        0: {
                            slidesPerView:1
                        },
                            600: {
                                slidesPerView:2
                            },
                            1200: {
                                slidesPerView:3
                            }
                        }}
                        spaceBetween={50}
                        loop={true}
                        navigation={true}
                        pagination={{clickable: true}}
                        modules={[Pagination, Navigation]}
                    >
                        { isSliderProductListNoEmpty && sliderProductList.map((card: IProductInfo) => (
                            <SwiperSlide key={card.id}>
                                <ProductCard cardInfo={card} isProductsBlock={true}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
            <MainPageLinkButton title={'ZOBACZ WSZYSTKIE PRODUKTY'} link={'/productList'}/>
        </div>
    </>
    )
})