import React from "react";
import './ProductCard.scss';
import {IProductInfo} from "../../../../interfaces";
import {useNavigate} from "react-router-dom";
import { validateString } from "../../../../utils";

interface IProps {
    cardInfo: IProductInfo;
    isProductsBlock?: boolean;
}

export const ProductCard: React.FC<IProps> = ({cardInfo, isProductsBlock}) => {
    const navigate = useNavigate()
    return (
        <div className={'productCard'}
             onClick={() => navigate(isProductsBlock ? `productList/${cardInfo.id}` : `${cardInfo.id}`)}>
            <div className={'productCard__imgArea'}>
                <img src={cardInfo.image} alt=""/>
            </div>
            <div className={'productCard__title'}>
                {
                    cardInfo.name && <span className={'productCard__title-name'} dangerouslySetInnerHTML={{__html: validateString(cardInfo.name)}}/>
                } <br/>
                <span className={'productCard__title-description'} dangerouslySetInnerHTML={{__html: validateString(cardInfo.title)}}/>
            </div>
        </div>
    )
}