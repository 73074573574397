import React from 'react';
import './MainPageLinkButton.scss';
import {useNavigate} from "react-router-dom";
import arrowRight from '../../../../assets/img/arrowRight10x16.png';

export const MainPageLinkButton: React.FC<{ title: string, link: string }> = ({title, link}) => {
    const navigate = useNavigate()
    return (
        <div className={'mainPageLinkButton'}>
            <button onClick={() => navigate(link)}>{title}
                <div className={'mainPageLinkButton__imageArea'}>
                    <img src={arrowRight} alt="arrow right"/>
                </div>
            </button>
        </div>
    )
}
