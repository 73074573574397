import {RootStore} from "./rootStore";
import {makeAutoObservable} from "mobx";
import {IFileCard, IQACard, IVideoCard} from "../interfaces";

export class KnowledgeStore {
    videoList: IVideoCard[] = [];
    fileList: IFileCard[] = [];
    qaList: IQACard[] = [];

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this)
    }

    setVideoList(videoList: IVideoCard[]) {
        this.videoList = [...videoList]
    }

    setFileList(fileList: IFileCard[]) {
        this.fileList = [...fileList]
    }

    setQAList(qaList: IQACard[]) {
        this.qaList = [...qaList]
    }
}