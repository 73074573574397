import React, {useRef} from 'react';
import {Route, Routes} from 'react-router-dom';
import {Header} from '../shared/header/Header';
import './App.scss';
import {MainPage} from "../pages/mainPage/MainPage";
import {Page404} from '../pages/page404/Page404';
import {Footer} from "../shared/footer/Footer";
import {Blog} from "../pages/blog/Blog";
import {BlogList} from '../pages/BlogList/BlogList';
import {defined, executeScroll} from "../../utils";
import {KnowledgeVideo} from "../pages/knowledgeVideo/KnowledgeVideo";
import {KnowledgeQA} from "../pages/knowledgeQA/KnowledgeQA";
import {KnowledgeFiles} from "../pages/knowledgefFiles/KnowledgeFiles";
import {ProductList} from "../pages/ProductList/ProductList";
import {Product} from "../pages/product/Product";

const App = () => {
    const contactRef = useRef<HTMLHeadingElement>(null)

    const contactScrollHandler = () => {
        executeScroll(defined(contactRef.current))
    }

    return (
        <div className={"app"}>
            <div className={'app__decor'}/>
            <Header contactScrollHandler={contactScrollHandler}/>
            <Routes>
                <Route path={'/'} element={<MainPage/>}/>
                <Route path={'/blog'} element={<BlogList/>}/>
                <Route path={'/blog/:id'} element={<Blog/>}/>
                <Route path={'/knowledge/video'} element={<KnowledgeVideo/>}/>
                <Route path={'/knowledge/files'} element={<KnowledgeFiles/>}/>
                <Route path={'/knowledge/qa'} element={<KnowledgeQA/>}/>
                <Route path={'/productList'} element={<ProductList/>}/>
                <Route path={'/productList/:id'} element={<Product/>}/>
                <Route path={'*'} element={<Page404/>}/>
            </Routes>
            <Footer contactRef={contactRef}/>
        </div>
    );
}

export default App;
