import {useCallback} from "react";
import {useApiCall} from "./useApiCalls";
import {useProductStore} from "../store/hooks";
import {IProductInfo } from "../interfaces";
import {ISingleProduct} from "../interfaces/singleProduct";

export const useProductApi = () => {
    const {api} = useApiCall()
    const productStore = useProductStore();

    const getProductList = useCallback(async () => {
        const res: IProductInfo[] = await api.apiInstance.get('products')
        productStore.setProductList(res)
    }, [api.apiInstance, productStore])

    const getSingleProduct = useCallback(async (productId: number) => {
        const res : ISingleProduct = await api.apiInstance.get(`product/${productId}`)
        productStore.setSingleProductInfo(res);
    }, [api.apiInstance, productStore])

    return {
        getProductList,
        getSingleProduct,
    }
}