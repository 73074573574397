import React from 'react';
import './Ozodrop.scss';
import {Grid} from "@mui/material";
import {ProductSubTitle} from "../productSubTitle/ProductSubTitle";
import ozodropImage from '../../../../assets/img/products/ozodrop.png';
import antiseptic from '../../../../assets/img/productSubTitle/antiseptic.png';
import regeneration from '../../../../assets/img/productSubTitle/regeneration.png';
import moisturizing from '../../../../assets/img/productSubTitle/moisturizing.png';
import {BuyButton} from "../../../shared/buttons/buyButton/BuyButton";

export const Ozodrop = () => {
    return (
        <>
            <Grid container className={'product__mainInfo'}>
                <Grid item lg={4} sm={6} pr={2}>
                    <ProductSubTitle
                        title={'ANTYSEPTYKA'}
                        descriptionList={[
                            'Uwalnianie atomowego tlenu odpowiada za redukcję wszystkich rodzajów patogenów:',
                            'Bakterii, w tym wielolekoopornych (92% redukcji CFU!)',
                            'Grzybów',
                            'Wirusów'
                        ]}
                        imageLink={antiseptic}
                    />
                    <ProductSubTitle
                        title={'REGENERACJA'}
                        description={'Obecność atomowego tlenu wspomaga regenację uszkodzonych tkanek - nabłonka i/lub macierzy rogówki.'}
                        imageLink={regeneration}
                    />
                </Grid>
                <Grid item lg={4} sm={6} pr={2}>
                    <img className={'product__imageArea'} src={ozodropImage} alt=""/>
                    <ProductSubTitle
                        title={'NAWILŻANIE'}
                        description={'Nanosomy uzupełniają ewentualne niedobory warstwy lipidowej filmu łzowego, a hypromeloza uzupełnia i stabilizuje warstwę wodną i śluzową.'}
                        imageLink={moisturizing}
                    />
                </Grid>
                <Grid item lg={4} sm={0}>
                    <img src={ozodropImage} alt=""/>
                    <h1 className={'product__additionalInfo-titleUp'}>OZODROP<sup>®</sup> – ANTYSEPTYCZNE KROPLE OCZNE Z OZONEM 3W1</h1>
                </Grid>
            </Grid>
            <Grid container className={'product__additionalInfo'}>
                <h1 className={'product__additionalInfo-title'}>OZODROP<sup>®</sup> – ANTYSEPTYCZNE KROPLE OCZNE Z OZONEM 3W1</h1>
                <Grid container className={'product__container'}>
                    <Grid item xl={0}/>
                    <Grid item sm={8} pr={2}>
                        <p>
                            ANTYSEPTYCZNE, piorunująco skuteczne (92% redukcji populacji bakterii!),
                            <br/>
                            WSPOMAGAJĄCE REGENERACJĘ i NAWILŻAJĄCE krople do oczu 3 w 1.
                        </p>
                        <p>
                            <strong>OZODROP<sup>®</sup></strong> to krople oczne bez konserwantów DLA DZIECI ORAZ DOROSŁYCH do
                            stosowania w leczeniu i profilaktyce infekcji o dowolnej etiologii. Krople oczne <br/>
                            <strong>OZODROP<sup>®</sup></strong> zawierają w swoim składzie unikalną formułę LipozonEye<sup>®</sup>
                            (ozonowany olej roślinny w nanosomach).
                        </p>
                        <p>
                            <strong>OZODROP<sup>®</sup></strong> to absolutny przełom w zapobieganiu i leczeniu infekcji ocznych
                            oraz bezpieczna dla oka alternatywa dla antybiotykoterapii.
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Sposób użycia:
                        </h3>
                        <p>
                            Przed użyciem wstrząsnąć. Zaaplikuj jedną lub dwie krople do każdego oka, raz bądź kilka
                            razy dziennie lub zgodnie z zaleceniami lekarza. Produkt może być używany podczas noszenia
                            soczewek kontaktowych.
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Skład:
                        </h3>
                        <p>
                            <strong>LipozonEye<sup>®</sup></strong> (ozonowany olej z nasion słonecznika, fosfolipidy sojowe),
                            hydroksypropylometyloceluloza, kwas borowy, tetraboran sodu, sól disodowa EDTA, PHMB, woda
                            demineralizowana.
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Przeciwwskazania:
                        </h3>
                        <p>
                            Nadwrażliwość na którykolwiek ze składników preparatu.
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Ostrzeżenia:
                        </h3>
                        <p>
                            Zastosowanie preparatu w przypadku uszkodzenia powierzchni oka może wywołać przemijające
                            uczucie szczypanie/pieczenie. Nie jest to przeciwwskazaniem do stosowania preparatu. Przed
                            użyciem należy zapoznać się z ulotką.
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Zawartość:
                        </h3>
                        <p>
                            Opakowanie zawiera butelkę zawierającą 8ml kropli do oczu.
                        </p>
                    </Grid>
                    <Grid item sm={4}>
                        <h2 className={'product__additionalInfo-subTitle'}>
                            DZIAŁANIE:
                        </h2>
                        <ul className={'product__additionalInfo-mainList'}>
                            <li>ANTYSEPTYCZNE</li>
                            <ul className={'product__additionalInfo-subList'}>
                                <li>Przeciwbakteryjne</li>
                                <li>Przeciwwirusowe</li>
                                <li>Przeciwgrzybicze</li>
                            </ul>
                            <li>WSPOMAGAJĄCE REGENERACJĘ</li>
                            <li>NAWILŻAJĄCE</li>
                        </ul>
                        <h2 className={'product__additionalInfo-subTitle'}>
                            WSKAZANIA:
                        </h2>
                        <ul className={'product__additionalInfo-mainList'}>
                            <li>„Czerwone oko”</li>
                            <ul className={'product__additionalInfo-subList'}>
                                <li>Zapalenia spojówek niezależnie od etiologii</li>
                                <li>Zapalenia rogówki niezależnie od etiologii</li>
                            </ul>
                            <li>Urazy oka</li>
                            <li>Po usunięciu ciała obcego</li>
                            <li>Po oparzeniach termicznych i chemicznych</li>
                            <li>Zespół Suchego Oka</li>
                            <li>Profilaktycznie w zapobieganiu infekcji u użytkowników soczewek kontaktowych</li>
                        </ul>

                        <p>
                            <strong>Producent:</strong><br/>
                            FB VISION S.p.A. – Via San Giovanni Scafa,<br/>
                            63074 San Benedetto del Tronto (AP)
                        </p>
                    </Grid>
                </Grid>
                <Grid container>
                    <BuyButton
                        linkAllegro={'https://allegro.pl/oferta/ozodrop-antyseptyczne-krople-oczne-z-ozonem-3w1-12058258389'}
                        linkInView={'https://sklep.inviewmedical.pl/pl/p/OZODROP-ANTYSEPTYCZNE-KROPLE-OCZNE-Z-OZONEM-3W1-/51'}
                    />
                </Grid>
            </Grid>
        </>
    )
}