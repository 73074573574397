export const validateString = (text: string) => {
  const lettersToReplace = ['a', 'i', 'o', 'u', 'w', 'z', 'A', 'I', 'O', 'U', 'W', 'Z'];
  lettersToReplace.forEach(el =>
    text
      .trim()
      .split(' ' + el + ' ')
      .join(' ' + el + '&nbsp;')
  );
  text = text.replace(/&lt;/gi, str => '<');
  text = text.replace(/&gt;/gi, str => '>');
  return text;
};

export const convertIframe = (html: string) => {
  //replace less‑than sign, greater than sign, quote sign
  const convertText = html.replaceAll("&lt;", "<").replaceAll("&gt;", ">").replaceAll("&quot;", "\"")

  return convertText;
}

//&lt;
//&gt;
//&quot;