import React from 'react';
import './BlogBlock.scss';
import {BlockTitle} from "../../../shared/BlockTitle/BlockTitle";
import {observer} from "mobx-react-lite";
import {useMainPageStore} from "../../../../store/hooks";
import {BlogBlockCard} from "../../../shared/cards/blogBlockCard/BlogBlockCard";
import {IBlogCard} from "../../../../interfaces";
import {MainPageLinkButton} from "../../../shared/buttons/mainPageLinkButton/MainPageLinkButton";

export const BlogBlock = observer(() => {
    const mainPageStore = useMainPageStore()
    const {blogBlockCards} = mainPageStore;


    return (
        <div className="blogBlock">
            <div className={'app__container'}>
                <BlockTitle title={'Blog'}/>
                <div className={'blogBlock__cardsArea'}>
                    {
                        !!blogBlockCards.length &&
                        blogBlockCards.map((card: IBlogCard) => <BlogBlockCard key={card.id} cardInfo={card}/>)
                    }
                </div>
            </div>
                <MainPageLinkButton title={'ZOBACZ WIĘCEJ'} link={'/blog'}/>
        </div>
    )
})