import React from 'react';
import './QACard.scss';
import {IQACard} from "../../../../interfaces";
import {validateString} from '../../../../utils';
import eyeIco from '../../../../assets/img/eye36x28.png';
import rightArrowIco from '../../../../assets/img/rightArrow.png';

interface IProps {
    cardInfo: IQACard;
    isActive: any;
    activeElementsToggler: (itemId: number) => void;
}

export const QACard: React.FC<IProps> = ({cardInfo, isActive, activeElementsToggler}) => {
    return (
        <div className={'qaCard'}>
            <div
                className={'qaCard-title'}
                onClick={()=>activeElementsToggler(cardInfo.id)}
            >
                <img src={eyeIco} alt="eye"/>
                <p>{cardInfo.title}</p>
                <img className={`qaCard__image ${isActive ? 'qaCard__image-active' : ''}`}  src={rightArrowIco} alt="arrow"/>
            </div>
            {
                isActive &&
                <p
                    className={'qaCard-content'}
                    dangerouslySetInnerHTML={{__html: validateString(cardInfo.content)}}
                />
            }
        </div>
    )
}