import React, {FC} from 'react';
import './BlogBlockCard.scss';
import {IBlogCard} from "../../../../interfaces";
import {useNavigate} from "react-router-dom";
import {validateString} from "../../../../utils";

interface IProps {
    cardInfo: IBlogCard;
}

export const BlogBlockCard: FC<IProps> = ({cardInfo}) => {
    const navigate = useNavigate();
    return (
        <div className={'blogBlockCard'} onClick={() => navigate(`/blog/${cardInfo.id}`)}>
            <img src={cardInfo.image} alt="card"/>
            <div className={'blogBlockCard__textArea'}>
                <h6>{cardInfo.title}</h6>
                <p dangerouslySetInnerHTML={{__html: validateString(cardInfo.abstract)}}/>
            </div>
            <span className={'blogBlockCard__button'}>czytaj więcej...</span>
        </div>
    )
}