import {useApiCall} from "./useApiCalls";
import {useKnowledgeStore} from "../store/hooks/useKnowledgeStore";
import {useCallback} from "react";
import {IFileCard, IQACard, IVideoCard} from "../interfaces";


export const useKnowledgeApi = () => {
    const {api} = useApiCall();
    const knowledgeStore = useKnowledgeStore();

    const getVideoList = useCallback(async () => {
        const res: IVideoCard[] = await api.apiInstance.get('films')
        knowledgeStore.setVideoList(res)
    }, [api.apiInstance, knowledgeStore])

    const getFileList = useCallback(async () => {
        const res: IFileCard[] = await api.apiInstance.get('articles')
        knowledgeStore.setFileList(res);
    }, [api.apiInstance, knowledgeStore])

    const getQAList = useCallback(async () => {
        const res: IQACard[] = await api.apiInstance.get('faqs')
        knowledgeStore.setQAList(res);
    }, [api.apiInstance, knowledgeStore])

    return {
        getVideoList,
        getFileList,
        getQAList,
    }
}