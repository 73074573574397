import {useRootStore} from "../RootStateContext";


export const usePoiStore = () => {
    const rootStore = useRootStore()

    if (!rootStore?.poiStore) {
        throw new Error('poi store should be defined')
    }

    return rootStore.poiStore;
}