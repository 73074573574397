import axios, { AxiosResponse } from "axios";
import {useMemo} from "react";
import {usePoiStore} from "../store/hooks";

export const useApiCall = () => {

    const poiStore = usePoiStore()

    const api = useMemo(() => {
        axios.defaults.baseURL = 'https://admin.wzrokok.pl/api';
        axios.defaults.headers.common['Content-Type'] = 'application/json';

        const responseBody = (response: AxiosResponse) => response.data

        const apiInstance = {
            get: (url: string) => {
                poiStore.setGlobalLoader()
                return axios.get(url)
                    .then(responseBody)
                    .catch(error => poiStore.setGlobalError(error))
                    .finally(() => poiStore.unsetGlobalLoader())
            },
            post: (url: string, body: {}) => {
                poiStore.setGlobalLoader()
                return axios.post(url, body)
                    .then(responseBody)
                    .catch(error => poiStore.setGlobalError(error))
                    .finally(() => poiStore.unsetGlobalLoader())
            },
            put: (url: string, body: {}) => {
                poiStore.setGlobalLoader()
                return axios.put(url, body)
                    .then(responseBody)
                    .catch(error => poiStore.setGlobalError(error))
                    .finally(() => poiStore.unsetGlobalLoader())
            }
        }
        return {apiInstance}
    }, [poiStore])

    return {api}
};