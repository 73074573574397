import React, {useEffect, useRef, useState} from 'react';
import './KnowledgeFiles.scss';
import {BlockTitle} from "../../shared/BlockTitle/BlockTitle";
import {observer} from 'mobx-react-lite';
import {useKnowledgeApi} from "../../../apiHooks/useKnowledgeApi";
import {useKnowledgeStore} from "../../../store/hooks/useKnowledgeStore";
import {IFileCard} from "../../../interfaces";
import {defined, executeScroll} from "../../../utils";
import {MaterialUIPagination} from "../../shared/pagination/MaterialUIPagination";
import {FileCard} from '../../shared/cards/fileCard/FileCard';

export const KnowledgeFiles = observer(() => {
    const {getFileList} = useKnowledgeApi()
    const knowledgeStore = useKnowledgeStore();
    const {fileList} = knowledgeStore;

    const [drawArray, setDrawArray] = useState<IFileCard[]>([]);
    const [pageDrawArray, setPageDrawArray] = useState<IFileCard[]>([]);
    const [pageCount, setPageCount] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const cardsPerPage = 6;

    const titleRef = useRef<HTMLHeadingElement>(null)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        void getFileList()
    }, [getFileList])

    useEffect(() => {
        if (!!fileList.length) {
            setDrawArray([...fileList])
            setPageCount(Math.ceil(fileList.length / cardsPerPage))
        }
    }, [fileList])

    useEffect(() => {
        setPageDrawArray([...drawArray.filter((card: IFileCard, index: number) => (index >= (currentPage - 1) * cardsPerPage) && (index < currentPage * cardsPerPage))])
    }, [drawArray, currentPage, cardsPerPage])

    return (
        <div className={'knowledgeFiles app__blockHeight'}>
            <div className={'app__container'}>
                <BlockTitle title={'Artykuły'} titleRef={titleRef}/>
                {
                    !!drawArray.length &&
					<>
						<div className={'knowledgeFiles__cardsArea'}>
                            {
                                !!pageDrawArray.length &&
                                pageDrawArray.map((card: IFileCard) => (
                                    <FileCard key={card.id} cardInfo={card}/>
                                ))
                            }
						</div>
						<MaterialUIPagination
							style={{marginTop: '50px', alignSelf: 'center', marginBottom: '50px'}}
							count={pageCount}
							page={currentPage}
							onChange={(e, value: number) => {
                                setCurrentPage(value)
                                executeScroll(defined(titleRef.current))
                            }}
						/>
					</>
                }
            </div>
        </div>
    )
})