import React, {useState} from 'react';
import './NavBar.scss';
import {HeaderButton} from "../buttons/headerButton/HeaderButton";
import {DropDownMenu} from "../dropDownMenu/DropDownMenu";
import menuIcon from './../../../assets/img/menuIcon.png'
import closeMenu from './../../../assets/img/closeMenu.png'
import eye21x19 from './../../../assets/img/eye21x19.png'
interface IProps {
    contactScrollHandler: () => void
}

export const NavBar: React.FC<IProps> = ({contactScrollHandler}) => {
    const [isMenuVisible, setMenuVisible ] = useState(false)
    const menuHandler = () => {
        setMenuVisible(!isMenuVisible)
    }

    const contactHandler = () => {
        setMenuVisible(false);
        contactScrollHandler()
    }

    return (
        <nav className={'navBar'}>
            <img src={!isMenuVisible ? menuIcon : closeMenu} alt="" onClick={menuHandler}/>
                <ul className={`navBar__list ${isMenuVisible ? 'navBar__list-active' : ''}`}>
                <HeaderButton link={'/'} title={'Strona główna'} setMenu={()=>setMenuVisible(false)}/>
                <HeaderButton link={'/blog'} title={'Blog'} setMenu={()=>setMenuVisible(false)}/>
                <DropDownMenu title={'Wiedza'}/>
                    <ul className={'navBar__mobileDropDown'}>
                        <li className={'navBar__button'}>Wiedza</li>
                        <HeaderButton link={'/knowledge/files'} title={'Artykuły'} setMenu={()=>setMenuVisible(false)}>
                            <img src={eye21x19} alt=""/></HeaderButton>
                        <HeaderButton link={'/knowledge/video'} title={'Filmy'} setMenu={()=>setMenuVisible(false)}>
                            <img src={eye21x19} alt=""/>
                        </HeaderButton>
                        <HeaderButton link={'/knowledge/qa'} title={'Q&A'} setMenu={()=>setMenuVisible(false)}>
                            <img src={eye21x19} alt=""/>
                        </HeaderButton>
                    </ul>
                <HeaderButton link={'/productList'} title={'Produkty'} setMenu={()=>setMenuVisible(false)}/>
                <button className={'headerButton'} onClick={contactHandler}>Kontakt</button>
            </ul>
        </nav>
    )
}