import React, {useEffect, useState} from 'react';
import './Product.scss';
import {BlockTitle} from "../../shared/BlockTitle/BlockTitle";
import {useParams} from "react-router-dom";
import {useProductStore} from "../../../store/hooks";
import {Ozodrop} from './ozodrop/Ozodrop';
import {BlefOx} from "./blefOx/BlefOx";
import {OzodropGel} from "./ozodropGel/OzodropGel";
import {Perflo} from "./perflo/Perflo";
import {MacuShield} from "./macuShield/MacuShield";
import {Ozolid} from "./ozolid/Ozolid";
import {CustomProduct} from "./customProduct/CustomProduct";

export const Product = () => {
    const {productList} = useProductStore();

    const [currentProduct, setCurrentProduct] = useState<any>(null);

    const params = useParams();

    useEffect(() => {
        if (params.id) {
            switch (params.id) {
                case 'c1':
                    setCurrentProduct(<Ozodrop/>)
                    break;
                case 'c2':
                    setCurrentProduct(<BlefOx/>)
                    break;
                case 'c3':
                    setCurrentProduct(<Perflo/>)
                    break;
                case 'c4':
                    setCurrentProduct(<OzodropGel/>)
                    break;
                case 'c5':
                    setCurrentProduct(<Ozolid/>)
                    break;
                case 'c6':
                    setCurrentProduct(<MacuShield/>)
                    break;
                default:
                    setCurrentProduct(<CustomProduct/>);

            }
        }
    }, [params.id, productList]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={'product app__blockHeight'}>
            <div className={'app__container'}>
                <BlockTitle title={'Produkty'}/>
                {
                    currentProduct
                }
            </div>
        </div>
    )
}