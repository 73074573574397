import React from 'react';
import {Grid} from "@mui/material";
import {MainPageLinkButton} from "../../../shared/buttons/mainPageLinkButton/MainPageLinkButton";
import './Slide.scss';
import {BuyButton} from "../../../shared/buttons/buyButton/BuyButton";
import mainEyeImage from '../../../../assets/img/animation/mainEye.png';
import { validateString } from '../../../../utils';

interface IProps {
    title: string;
    link: string;
    drugImage: string;
    animatedEyeImage: string;
    linkAllegro: string;
    linkInView: string;
}

export const Slide: React.FC<IProps> = ({title, link, linkAllegro, linkInView, drugImage, animatedEyeImage}) => {
    return (
        <Grid className={'slide'} container>
            <Grid item xs={12}  sm={6} className={'slide__leftSide'} alignItems={'center'}>
                <div className={'slide__leftSide-decorCircle'}/>
                <div className={'slide__buttons-mobile'}>
                    <BuyButton linkAllegro={linkAllegro} linkInView={linkInView}/>
                    <MainPageLinkButton title={'DOWIEDZ SIĘ WIĘCEJ'} link={link}/>
                </div>
                <img className={'slide__leftSide-drugImage'} src={drugImage} alt=""/>
            </Grid>
            <Grid item xs={0} sm={6} className={'slide__rightSide'}>

                    <img className={'slide__rightSide-mainImage'} src={mainEyeImage} alt=""/>
                    <img className={'slide__rightSide-animateImage'} src={animatedEyeImage} alt=""/>

            </Grid>
            <Grid item xs={12} className={'slide__bottomSide'}>
                <Grid className={'slide__bottomSide-content'}>
                    <h1 dangerouslySetInnerHTML={{__html: validateString(title)}}/>
                    <MainPageLinkButton title={'DOWIEDZ SIĘ WIĘCEJ'} link={link}/>
                </Grid>
            </Grid>
        </Grid>
    )
}