import React from 'react';
import './KnowledgeBlock.scss';
import {BlockTitle} from "../../../shared/BlockTitle/BlockTitle";
import {KnowledgeBlockLinkButton} from "../../../shared/buttons/knowledgeBlockLinkButton/KnowledgeBlockLinkButton";

export const KnowledgeBlock = () => {
    return (
        <>
            <div className={'knowledgeBlock'}>
                <div className={'app__container'}>
                    <BlockTitle title={'Gołym okiem'} subTitle={'- biblioteka wiedzy'}/>
                    <div className={'knowledgeBlock__cardsArea'}>
                        <KnowledgeBlockLinkButton link={'/knowledge/files'} title='ARTYKUŁY' img={1}/>
                        <KnowledgeBlockLinkButton link={'/knowledge/video'} title='FILMY' img={2}/>
                        <KnowledgeBlockLinkButton link={'/knowledge/qa'} title='Q&A' img={3}/>
                    </div>
                </div>

            </div>
        </>
    )
}