import React from 'react';
import './BlefOx.scss';
import {Grid} from "@mui/material";
import {ProductSubTitle} from "../productSubTitle/ProductSubTitle";
import antiseptic from "../../../../assets/img/productSubTitle/antiseptic.png";
import regeneration from "../../../../assets/img/productSubTitle/regeneration.png";
import moisturizing from "../../../../assets/img/productSubTitle/moisturizing.png";
import blefOxImage from "../../../../assets/img/products/blefox.png";
import {BuyButton} from "../../../shared/buttons/buyButton/BuyButton";

export const BlefOx = () => {
    return (
        <>
            <Grid container className={'product__mainInfo'}>
                <Grid item lg={4} sm={6} pr={2}>
                    <ProductSubTitle
                        title={'ANTYSEPTYKA'}
                        descriptionList={[
                            'Uwalnianie atomowego tlenu odpowiada za redukcję wszystkich rodzajów patogenów:',
                            'Bakterii, w tym wielolekoopornych (92% redukcji CFU!)',
                            'Grzybów',
                            'Wirusów'
                        ]}
                        imageLink={antiseptic}
                    />
                    <ProductSubTitle
                        title={'REGENERACJA'}
                        description={'Obecność atomowego tlenu wspomaga regenację uszkodzonych tkanek - nabłonka i/lub macierzy rogówki.'}
                        imageLink={regeneration}
                    />
                </Grid>
                <Grid item lg={4} sm={6} pr={2}>
                    <img className={'product__imageArea'} src={blefOxImage} alt=""/>
                    <ProductSubTitle
                        title={'NAWILŻANIE'}
                        description={'Nanosomy uzupełniają ewentualne niedobory warstwy lipidowej filmu łzowego, a hypromeloza uzupełnia i stabilizuje warstwę wodną i śluzową.'}
                        imageLink={moisturizing}
                    />
                </Grid>
                <Grid item lg={4} sm={0}>
                    <img src={blefOxImage} alt=""/>
                    <h1 className={'product__additionalInfo-titleUp'}>BlefOX<sup>®</sup> – ANTYSEPTYCZNA PIANKA Z OZONEM 5W1</h1>
                </Grid>
            </Grid>
            <Grid container className={'product__additionalInfo'}>
                <h1 className={'product__additionalInfo-title'}>BlefOX<sup>®</sup> – ANTYSEPTYCZNA PIANKA Z OZONEM 5W1</h1>
                <Grid container className={'product__container'}>
                    <Grid item xl={0}/>
                    <Grid item sm={8} pr={2}>
                        <p>
                            Specjalistyczna pianka okulistyczna <strong>BlefOX<sup>®</sup></strong> przeznaczona jest do higieny
                            brzegów powiek u dzieci i dorosłych w stanach takich jak: zapalenie brzegów powiek, nużyca
                            powiek, zapalenie spojówek, jęczmień czy uraz okolic oczu. W składzie zawiera trzy kluczowe
                            składniki:
                            <br/>
                            1. LipozonEye<sup>®</sup> (o działaniu ANTYSEPTYCZNYM, WSPOMAGAJĄCYM REGENERACJĘ i NAWILŻAJĄCYM)
                            zapewnia szerokie spektrum działania antybakteryjnego i eliminację biofilmu
                            <br/>
                            2. Eugenol, który dzięki zawartym w nim akarycydom eliminuje nużeńce
                            <br/>
                            3. Żel aloesowy - likwiduje podrażnienia, zaczerwienienie, działałagodząco i
                            przeciwzapalnie.
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Sposób użycia:
                        </h3>
                        <p>
                            Przed użyciem <strong>BlefOX<sup>®</sup></strong> dokładnie umyj ręce. Naciśnij dozownik, by nałożyć piankę na wacik znajdujący się
                            w opakowaniu, na czystą gazę lub na opuszki palców. W przypadku stosowania przed lub po zabiegu
                            chirurgicznym, piankę należy nakładać na gazę jałową. Zamknij oczy i masuj powieki pianką. Nie otwieraj
                            oczu podczas aplikacji. Pozostaw piankę na około 1 minutę i powtarzaj aplikację 2-3 razy dziennie przez
                            co najmniej 7 dni. Opłucz powieki i rzęsy ciepłą wodą po każdej aplikacji. Powtórz te same kroki dla
                            drugiego oka.
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Skład:
                        </h3>
                        <p>
                            <strong>LipozonEye<sup>®</sup></strong> (ozonowany olej z nasion słonecznika, liposomy), Liofilizowany żel Aloe Vera, Olejek
                            eteryczny Eugenia caryophyllus, Kokamidopropylobetaina, Propanediol, Kokoiloglutaminian dwusodu,
                            Hydroksyacetofenon, Kokoiloglutaminian sodu, O-cymen-5-ol, woda dejonizowana.
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Przeciwwskazania:
                        </h3>
                        <p>
                            Nadwrażliwosć na którykolwiek ze składników preparatu.
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Ostrzeżenia:
                        </h3>
                        <p>
                            Do użytku zewnętrznego. Przed użyciem należy zapoznać się z ulotką.
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Zawartość:
                        </h3>
                        <p>
                            Opakowanie zawiera pojemnik z 50 ml pianki oraz 60 sztuk płatków kosmetycznych do aplikacji.
                            <br/>
                            Opakowanie zawiera 120 dawek.
                        </p>
                    </Grid>
                    <Grid item sm={4}>
                        <h2 className={'product__additionalInfo-subTitle'}>
                            DZIAŁANIE:
                        </h2>
                        <ul className={'product__additionalInfo-mainList'}>
                            <li>ANTYSEPTYCZNE</li>
                            <ul className={'product__additionalInfo-subList'}>
                                <li>Przeciwbakteryjne</li>
                                <li>Przeciwwirusowe</li>
                                <li>Przeciwgrzybicze</li>
                            </ul>
                            <li>WSPOMAGAJĄCE REGENERACJĘ</li>
                            <li>NAWILŻAJĄCE</li>
                            <li>ANTYNUŻEŃCOWE</li>
                            <li>KOJĄCO ŁAGODZĄCE</li>
                        </ul>
                        <h2 className={'product__additionalInfo-subTitle'}>
                            WSKAZANIA:
                        </h2>
                        <ul className={'product__additionalInfo-mainList'}>
                            <li>Nużyca powiek</li>
                            <li>Zapalenie brzegów powiek</li>
                            <li>Zapalenie brzegów powiek z zapaleniem spojówek</li>
                            <li>Przed i po zabiegach chirurgicznych okolicy oczu</li>
                            <li>Po zabiegach medycyny estetycznej</li>
                        </ul>

                        <p>
                            <strong>Producent:</strong><br/>
                            FB VISION S.p.A. – Via San Giovanni Scafa,<br/>
                            63074 San Benedetto del Tronto (AP)
                        </p>
                    </Grid>
                </Grid>
                <Grid container>
                    <BuyButton
                        linkAllegro={'https://allegro.pl/oferta/blefox-antyseptyczna-pianka-z-ozonem-5w1-12091051015'}
                        linkInView={'https://sklep.inviewmedical.pl/pl/p/BlefOX-ANTYSEPTYCZNA-PIANKA-Z-OZONEM-5W1/75'}
                    />
                </Grid>
            </Grid>
        </>
    )
}