import React from "react";
import './FileCard.scss';
import {IFileCard} from "../../../../interfaces";
import pdfIco from '../../../../assets/img/pdfIco.png';

interface IProps {
    cardInfo: IFileCard;
}

export const FileCard: React.FC<IProps> = ({cardInfo}) => {
    return (
        <a
            className={'fileCard'}
            href={cardInfo.file}
            target='_blank'
            rel='noreferrer'
        >
            <img src={pdfIco} alt="pdf icon"/>
            <p>{cardInfo.title}</p>
        </a>
    )
}