import React, {useEffect, useRef, useState} from 'react';
import './BlogList.scss';
import {BlockTitle} from "../../shared/BlockTitle/BlockTitle";
import {observer} from "mobx-react-lite";
import {useBlogStore} from "../../../store/hooks";
import {MaterialUIPagination} from "../../shared/pagination/MaterialUIPagination";
import {IBlogCard} from "../../../interfaces";
import {defined, executeScroll} from '../../../utils';
import {BlogBlockCard} from "../../shared/cards/blogBlockCard/BlogBlockCard";
import {useBlogApi} from "../../../apiHooks/useBlogApi";

export const BlogList = observer(() => {
    const {getBlogList} = useBlogApi()

    const blogStore = useBlogStore()
    const {blogList} = blogStore;

    const [drawArray, setDrawArray] = useState<IBlogCard[]>([]);
    const [pageDrawArray, setPageDrawArray] = useState<IBlogCard[]>([]);
    const [pageCount, setPageCount] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const cardsPerPage = 12;

    const titleRef = useRef<HTMLHeadingElement>(null)

    useEffect(() => {
        void getBlogList();
    }, [getBlogList])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (!!blogList.length) {
            setDrawArray([...blogList])
            setPageCount(Math.ceil(blogList.length / cardsPerPage))
        }
    }, [blogList])

    useEffect(() => {
        setPageDrawArray([...drawArray.filter((card, index) => (index >= (currentPage - 1) * cardsPerPage) && (index < currentPage * cardsPerPage))])
    }, [drawArray, currentPage, cardsPerPage])

    return (
        <div className={'blogList app__blockHeight'}>
            <div className={'app__container'}>
                <BlockTitle title={'Blog'} titleRef={titleRef}/>
                {
                    !!drawArray.length &&
					<>
						<div className={'blogList__cardsArea'}>
                            {
                             !!pageDrawArray.length &&
                                pageDrawArray.map((card: IBlogCard) => (
                                    <BlogBlockCard key={card.id} cardInfo={card}/>
                                ))
                            }
						</div>
						<MaterialUIPagination
							style={{marginTop: '50px', alignSelf: 'center', marginBottom: '70px'}}
							count={pageCount}
							page={currentPage}
							onChange={(e, value: number) => {
                                setCurrentPage(value)
                                executeScroll(defined(titleRef.current))
                            }}
						/>
					</>
                }
            </div>
        </div>
    )
})