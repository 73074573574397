import {styled} from '@mui/material/styles';
import {Pagination} from '@mui/material';

export const MaterialUIPagination = styled(Pagination)(({theme}) => ({
    "& .MuiButtonBase-root": {
        color: '#3F484F',
    },
    "& .MuiPaginationItem-ellipsis": {
        color: '#72CEEF',
    },
    "& .MuiPagination-ul": {
        justifyContent: 'center',
    },
    "& .Mui-selected": {
        // backgroundColor: 'rgba(255, 255, 255, .1) !important',
        backgroundColor: '#72CEEF !important',
        color: '#3F484F',
        fontWeight: '700',
    },
    "& .MuiPaginationItem-icon": {
        color: '#3F484F',
    },
}))