import {useRootStore} from "../RootStateContext";


export const useKnowledgeStore = () => {
    const rootStore = useRootStore();

    if (!rootStore?.knowledgeStore) {
        throw new Error('knowledge store should be defined')
    }

    return rootStore.knowledgeStore
}