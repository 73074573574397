import React from 'react';
import { validateString } from '../../../../utils';
import './ProductSubTitle.scss';

interface IProps {
    title: string;
    description?: string;
    descriptionList?: string[];
    imageLink: string;
}

export const ProductSubTitle: React.FC<IProps> = ({ title, description, descriptionList, imageLink}) => {
    return (
        <div className={'productSubTitle'}>
            <div className={'productSubTitle__imgWrapper'}>
                <div/>
                <img src={imageLink} alt=""/>
            </div>
            <div className={'productSubTitle__textWrapper'}>
                <h3>{title}</h3>
                {
                    description && <p dangerouslySetInnerHTML={{__html: validateString(description)}}/>
                }
                {
                    descriptionList &&
                    <ul>
                        {
                            descriptionList.map((el, index) => <li key={index}>{el}</li>)
                        }
                    </ul>
                }
            </div>
        </div>
    )
}