import React, {useState} from 'react';
import './BuyButton.scss';
import basketImage from '../../../../assets/img/basket.png';

interface IProps {
    linkAllegro: string | null;
    linkInView: string | null;
}

export const BuyButton: React.FC<IProps> = ({linkAllegro, linkInView}) => {
    const [isDropDownActive, setIsDropDownActive] = useState(false)
    const toggleMenuHandler = () => setIsDropDownActive(!isDropDownActive)
    const closeMenuHandler = () => setIsDropDownActive(false)

    const isLinkAllegroActive = linkAllegro !== null
    const isLinkInViewActive = linkInView !== null

    return (
        <div className={'buyButton'} onMouseLeave={closeMenuHandler} >
            <div className={'buyButton__buttonWrapper'}>
                <button
                    className={'buyButton__mainButton'}
                    onClick={toggleMenuHandler}
                >
                    <div className={'buyButton__imageArea'}>
                        <img src={basketImage} alt=""/>
                    </div>
                    KUP TERAZ
                </button>
                <ul className={`buyButton__dropZone ${isDropDownActive ? 'buyButton__dropZone-show' : ''}`}>
                    {isLinkAllegroActive && <li>
                        <a
                            href={linkAllegro}
                            target='_blank'
                            rel='noreferrer'
                        >
                            Kup na Allegro
                        </a>
                    </li>}
                    {isLinkInViewActive && <li>
                        <a
                            href={linkInView}
                            target='_blank'
                            rel='noreferrer'
                        >
                            Kup na InView
                        </a>
                    </li>}
                </ul>
            </div>
        </div>
    )
}