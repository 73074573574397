import React, {createContext, useContext} from "react";
import {RootStore} from "./rootStore";

let store: RootStore;

const RootStateContext = createContext<RootStore | undefined>(undefined)

interface IProps {
    children: React.ReactNode,
}

const initializeStore = (): RootStore => {
    const _store = store ?? new RootStore();

    // For SSG and SSR always create a new store
    if (typeof window === 'undefined') {
        return _store;
    }
    // Create the store once in the client
    if (!store) {
        store = _store;
    }

    return _store;
};

export const RootStateProvider: React.FC<IProps> = ({children}) =>
    <RootStateContext.Provider value={initializeStore()}>
        {children}
    </RootStateContext.Provider>

export const useRootStore = () => useContext(RootStateContext)