import React, {useEffect, useState} from 'react';
import './KnowledgeQA.scss';
import {useKnowledgeApi} from "../../../apiHooks/useKnowledgeApi";
import {useKnowledgeStore} from "../../../store/hooks/useKnowledgeStore";
import {BlockTitle} from "../../shared/BlockTitle/BlockTitle";
import {observer} from "mobx-react-lite";
import {IQACard} from "../../../interfaces";
import {QACard} from "../../shared/cards/qaCard/QACard";

export const KnowledgeQA = observer(() => {
    const {getQAList} = useKnowledgeApi()
    const knowledgeStore = useKnowledgeStore()
    const {qaList} = knowledgeStore;

    const [drawArray, setDrawArray] = useState<IQACard[]>([])
    const [activeItems, setActiveItems] = useState<number[]>([])

    const activeElementsToggler = (itemId: number) => {
        setActiveItems(prev => prev.indexOf(itemId) >= 0 ? [...prev.filter(el => el !== itemId)] : [...prev, itemId])
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        void getQAList();
    }, [getQAList])

    useEffect(() => {
        if (!!qaList.length) {
            setDrawArray([...qaList])
        }
    }, [qaList])

    return (
        <div className={'knowledgeQA app__blockHeight'}>
            <div className={'app__container'}>
                <BlockTitle title={'Q&A'}/>
                <div className={'knowledgeQA__cardsArea'}>
                    {
                        !!drawArray.length &&
                        drawArray.map((card: IQACard) => (
                            <QACard
                                key={card.id}
                                cardInfo={card}
                                isActive={activeItems.indexOf(card.id) >= 0}
                                activeElementsToggler={activeElementsToggler}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    )
})