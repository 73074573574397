import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material';
import './scss/main.scss';
import App from './components/app/App';
import {BrowserRouter} from "react-router-dom";
import {RootStateProvider} from './store/RootStateContext';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1489
        }
    }
});

const app = (
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <RootStateProvider>
                <App/>
            </RootStateProvider>
        </BrowserRouter>
    </ThemeProvider>
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(app);