import React from "react";
import './MacuShield.scss';
import {Grid} from "@mui/material";
import {ProductSubTitle} from "../productSubTitle/ProductSubTitle";
import eyeProtection from "../../../../assets/img/productSubTitle/eyeProtection.png";
import mesozeaxanthin from "../../../../assets/img/productSubTitle/mesozeaxanthin.png";
import vitamins from "../../../../assets/img/productSubTitle/vitamins.png";
import minerals from "../../../../assets/img/productSubTitle/minerals.png";
import macushield from "../../../../assets/img/products/macushield.png";
import {BuyButton} from "../../../shared/buttons/buyButton/BuyButton";

export const MacuShield = () => {
    return (
        <>
            <Grid container className={'product__mainInfo'}>
                <Grid item lg={4} sm={6} pr={2}>
                    <ProductSubTitle
                        title={'ochrona plamki oka'}
                        description={'Poprzez uzupełnianie niedoborów karotenoidów plamkowych oraz wspieranie fizjologicznej ochrony jej komórek.'}
                        imageLink={eyeProtection}
                    />
                    <ProductSubTitle
                        title={'LUTEINA I mezozeaksantynA'}
                        description={'Jedyny zarejestrowany w Polsce preparat zawierający luteinę i zeaksantynę.'}
                        imageLink={mesozeaxanthin}
                    />
                </Grid>
                <Grid item lg={4} sm={6} pr={2} className={'product__additionalInfo-hide'}>
                    <img className={'product__imageArea'} src={macushield} alt=""/>
                    <ProductSubTitle
                        title={'WITAMINY'}
                        description={'Unikalne połączenie wszystkich trzech karotenoidów plamkowych oraz witaminę C i E.'}
                        imageLink={vitamins}
                    />
                    <ProductSubTitle
                        title={'MINERAŁY'}
                        description={'Zawiera cynki i miedź odpowiedzialne za eliminację wolnych rodników.'}
                        imageLink={minerals}
                    />
                </Grid>
                <Grid item lg={4} sm={0}>
                    <img src={macushield} alt=""/>
                    <h1 className={'product__additionalInfo-titleUp'}>MacuShield<sup>®</sup> GOLD 30 -
                        Luteina-Mezozeaksantyna-Zeaksantyna (LMZ<sup>®</sup>) + witaminy + minerały</h1>
                </Grid>
            </Grid>
            <Grid container className={'product__additionalGrid'}>
                <Grid item xs={0} sm={6} lg={0} >
                    <ProductSubTitle
                    title={'MINERAŁY'}
                    description={'Zawiera cynki i miedź odpowiedzialne za eliminację wolnych rodników.'}
                    imageLink={minerals}
                />
                </Grid>
            </Grid>
            <Grid container className={'product__additionalInfo'}>
                <h1 className={'product__additionalInfo-title'}>MacuShield<sup>®</sup> GOLD 30 -
                    (LMZ<sup>®</sup>) + witaminy + minerały</h1>
                <Grid container className={'product__container'}>
                    <Grid item xl={0}/>
                    <Grid item sm={8} pr={2}>
                        <p>
                            <strong>MacuShield<sup>®</sup> GOLD</strong> jest okulistycznym suplementem diety przeznaczonym dla
                            osób w średnim i
                            zaawansowanym stadium AMD, zagrożonych AMD, jak również osób z innymi schorzeniami
                            siatkówki. Zapewnia kompleksową ochronę plamki oka, znajdującej się w centralnej części
                            siatkówki i odpowiedzialnej za rozpoznawanie barw i szczegółów, poprzez uzupełnianie
                            niedoborów karotenoidów plamkowych oraz wspieranie fizjologicznej ochrony jej komórek.
                        </p>
                        <p>
                            Preparat <strong>MacuShield<sup>®</sup> GOLD</strong> zawiera w składzieformułę LMZ<sup>3</sup>, czyli unikalne
                            połączenie
                            wszystkich trzech karotenoidów plamkowych oraz witaminę C i E, cynk i miedź. Witaminy C i E
                            są przeciwutleniaczami, chroniącymi komórki plamki przed stresem oksydacyjnym. Cynk i miedź
                            to kofaktory i składnikiokoło 300 enzymów, w tym występującej w oku dysmutazy
                            ponadtlenkowej, odpowiedzialnej za eliminację wolnych rodników.
                        </p>
                        <p>
                            Produkty z linii <strong>MacuShield<sup>®</sup></strong> są jedynymi zarejestrowanymi w Polsce
                            preparatami zawierającymi
                            nie tylko luteinę i zeaksantynę, ale również mezo-zeaksantynę, czyli wszystkie trzy
                            karotenoidy plamkowe. Jest to o tyle istotne, że mezo-zeaksantyna nie występuje w
                            pożywieniu, a ok. 12% populacji nie posiada zdolności wytwarzania tego związku.
                        </p>
                        <p>
                            Skład preparatu zgodny z zaleceniami EFSA oraz wnioskami płynącymi z badań AREDS2. Brak jest
                            jakichkolwiek przeciwwskazań do spożywania suplementu.
                        </p>
                        <p>
                            Opakowanie <strong>MacuShield<sup>®</sup> GOLD 30</strong> zawiera 90 kapsułek, pozwalającą na
                            spożywanie suplementu
                            przez miesiąc (30 dni). Zalecane spożycie to 3 kapsułki dziennie, zażywane podczas posiłku.
                            Jedna kapsułka zawiera: Luteina 10 mg, Mezo-zeaksantyna 10 mg, Zeaksantyna 2 mg,Cynk 25 mg,
                            Witamina C 500 mg, Witamina E 268 mg, Miedź 2000 μg.
                        </p>
                        <p>
                            Stosowanie produktów <strong>MacuShield<sup>®</sup></strong> umożliwia utrzymanie i
                            poprawę prawidłowegowidzenia.
                            Skuteczność działania preparatów <strong>MacuShield<sup>®</sup></strong> została wielokrotnie
                            potwierdzone badaniami
                            klinicznymi.
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Sposób użycia:
                        </h3>
                        <p>
                            Doustnie. Raz dziennie, 3 kapsułki dziennie w trakcie posiłku.
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Skład:
                        </h3>
                        <p>
                            Kwas L-askorbinowy, otoczka kapsułki (środekżelujący: żelatyna bydlęca, środek utrzymujący
                            wilgoć: gliceryna, barwniki: czerwony tlenek żelaza E172, ditlenek tytanu E171), tokoferol
                            d-alfa, środek wypełniający: olej słonecznikowy, wyciąg z nagietka (luteina,
                            mezo-zeaksantyna, zeaksantyna), monohydrat siarczanu cynku, zagęszczacz: ditlenek krzemu,
                            emulgator: lecytyna słonecznikowa, bezwodny siarczan miedzi.
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Przeciwwskazania:
                        </h3>
                        <p>
                            -
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Ostrzeżenia:
                        </h3>
                        <p>
                            Nie przekraczać zalecanej dziennie porcji. Zrównoważony sposób żywienia i prawidłowy tryb
                            życia jest ważny dla funkcjonowania organizmu człowieka. Suplementacja diety nie może być
                            stosowana jako substytut (zamiennik) zróżnicowanej diety.<br/>
                            Nie należy stosować w przypadku nadwrażliwości na którykolwiek ze składników. Stosowanie
                            podczas ciąży i laktacji skonsultować z lekarzem. Należy poinformować lekarza o
                            przyjmowanych suplementach diety podczas każdej wizyty lekarskiej. <br/>
                            Przechowywać w szczelnym opakowaniu w temperaturze 15-25 stopni Celsjusza, chronić przed
                            promieniami słonecznymi. Przechowywać w sposób niedostępny dla dzieci. Produkt bezglutenowy.
                            Przed użyciem należy zapoznać się z ulotką.
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Zawartość:
                        </h3>
                        <p>
                            Opakowanie zawiera 90 kapsułek żelowych.
                        </p>
                    </Grid>
                    <Grid item sm={4}>
                        <h2 className={'product__additionalInfo-subTitle'}>
                            DZIAŁANIE:
                        </h2>
                        <ul className={'product__additionalInfo-mainList'}>
                            <li>NAWILŻAJĄCE</li>
                            <li>ANTYOKSYDACYJNE</li>
                            <li>ANTYALERGICZNE</li>
                            <li>PRZECIWZAPALNE</li>
                        </ul>
                        <h2 className={'product__additionalInfo-subTitle'}>
                            WSKAZANIA:
                        </h2>
                        <ul className={'product__additionalInfo-mainList'}>
                            <li>Zespół Suchego Oka</li>
                            <ul className={'product__additionalInfo-subList'}>
                                <li>Długotrwałe korzystanie z monitora/smartfona/TV</li>
                                <li>Przebywanie w klimatyzowanych lub ogrzewanych pomieszczeniach</li>
                                <li>Objawy takie jak(*): pieczenie oczy, dyskomfort</li>
                                <li>szczypanie, uczucie piasku pod powiekami</li>
                            </ul>
                            <li>Zaburzenia powierzchni oka</li>
                            <li>Chemiczne podrażnienia spojówek, np. po basenie</li>
                        </ul>
                        <p>* Po konsultacji z lekarzem i wykluczeniu chorób oczu</p>
                        <p>
                            <strong>Producent:</strong><br/>
                            FB VISION S.p.A. – Via San Giovanni Scafa,<br/>
                            63074 San Benedetto del Tronto (AP)
                        </p>
                    </Grid>
                </Grid>
                <Grid container>
                    <BuyButton
                        linkAllegro={'https://allegro.pl/oferta/macushield-gold-30-szt-zwyrodnienia-plamki-zoltej-12091052647'}
                        linkInView={'https://sklep.inviewmedical.pl/pl/p/MacuShield-GOLD-30-Luteina-Mezozeaksantyna-Zeaksantyna-LMZ-witaminy-mineraly/36'}
                    />
                </Grid>
            </Grid>
        </>
    )
}