import React, {useEffect} from 'react';
import './Blog.scss';
import {observer} from "mobx-react-lite";
import {useBlogStore} from "../../../store/hooks";
import {BlockTitle} from "../../shared/BlockTitle/BlockTitle";
import {IBlogCard} from "../../../interfaces";
import {BlogBlockCard} from "../../shared/cards/blogBlockCard/BlogBlockCard";
import {useBlogApi} from "../../../apiHooks/useBlogApi";
import {useParams} from "react-router-dom";
import {validateString} from "../../../utils";
import {BlogNavigationButton} from "../../shared/buttons/blogNavigationButton/BlogNavigationButton";

export const Blog = observer(() => {
    const {getSingleBlogInfo} = useBlogApi()
    const blogStore = useBlogStore();
    const {singleBlogInfo} = blogStore;

    const params = useParams()

    useEffect(() => {
        if (params.id) {
            void getSingleBlogInfo(+params.id)
        }
    }, [getSingleBlogInfo, params.id])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [singleBlogInfo])

    return (
        <div className={'blog app__blockHeight'}>
            <div className={'app__container'}>
                <BlockTitle title={'Blog'}/>
            </div>
                {
                    !!singleBlogInfo &&

					<div className={'blog__content app__container'}>
						<img className={'blog__imageArea'} src={singleBlogInfo.blog.image} alt=""/>

                        <h5>{singleBlogInfo.blog.title}</h5>

                        <p dangerouslySetInnerHTML={{__html: validateString(singleBlogInfo.blog.content)}}/>
                        <div className={'blog__content-buttonArea'}>
                            <BlogNavigationButton title={'< Poprzedni'} blogId={singleBlogInfo.blog.previousId}/>
                            <BlogNavigationButton title={'Następny >'} blogId={singleBlogInfo.blog.nextId}/>
                        </div>
                    </div>
                }
                <div className={'app__container'}>
                    <h6 className={'blog__anotherArticle'}>Zobacz inne:</h6>
                    <div className={'blog__cardsArea'}>
                        {
                            !!singleBlogInfo?.others?.length &&
                            singleBlogInfo.others.map((card: IBlogCard) => <BlogBlockCard key={card.id} cardInfo={card}/>)
                        }
                    </div>
                </div>
        </div>
    )
})