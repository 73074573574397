import {makeAutoObservable} from "mobx";
import {RootStore} from "./rootStore";

export class PoiStore {

    isGlobalLoader = false
    isGlobalError = false
    errorMessage: string | null = null
    errorCode: number | null = null

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    setGlobalLoader = () => this.isGlobalLoader = true;
    unsetGlobalLoader = () => this.isGlobalLoader = false;

    setGlobalError = (data: {
        status: number,
        message: string
    }) => {
        this.isGlobalError = true;
        this.errorCode = data.status ? data.status : 404;
        this.errorMessage = data.message ? data.message : 'skontaktuj się z administratorem';
    }

    unsetGlobalError = () => {
        this.isGlobalError = false;
        this.errorMessage = null;
        this.errorCode = null;
    }
}
