import React, {useEffect, useRef, useState} from "react";
import './KnowledgeVideo.scss';
import {BlockTitle} from "../../shared/BlockTitle/BlockTitle";
import {useKnowledgeApi} from "../../../apiHooks/useKnowledgeApi";
import {useKnowledgeStore} from "../../../store/hooks/useKnowledgeStore";
import {IVideoCard} from "../../../interfaces";
import {VideoCard} from "../../shared/cards/videoCard/VideoCard";
import {MaterialUIPagination} from "../../shared/pagination/MaterialUIPagination";
import {defined, executeScroll} from "../../../utils";
import {VideoArea} from "./videoArea/VideoArea";
import {observer} from "mobx-react-lite";

export const KnowledgeVideo = observer(() => {

    const {getVideoList} = useKnowledgeApi()
    const knowledgeStore = useKnowledgeStore()
    const {videoList} = knowledgeStore;

    const [drawArray, setDrawArray] = useState<IVideoCard[]>([]);
    const [pageDrawArray, setPageDrawArray] = useState<IVideoCard[]>([]);
    const [pageCount, setPageCount] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const cardsPerPage = 4;

    const [videoToShow, setVideoToShow] = useState<string | null>(null)


    const titleRef = useRef<HTMLHeadingElement>(null)

    useEffect(() => {
        void getVideoList()
    }, [getVideoList])

    useEffect(() => {
        if (!!videoList.length) {
            setDrawArray([...videoList])
            setPageCount(Math.ceil(videoList.length / cardsPerPage))
        }
    }, [videoList])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        setPageDrawArray([...drawArray.filter((card, index) => (index >= (currentPage - 1) * cardsPerPage) && (index < currentPage * cardsPerPage))])
    }, [drawArray, currentPage, cardsPerPage])

    return (
        <div className={'knowledgeVideo app__blockHeight'}>
            <div className={'app__container'}>
                <BlockTitle title={'Filmy'} titleRef={titleRef}/>
                {
                    videoToShow
                        ?
                        <VideoArea setVideoToShow={setVideoToShow} videoToShow={videoToShow}/>
                        :
                        !!drawArray.length &&
						<>
							<div className={'knowledgeVideo__cardsArea'}>
                                {
                                    !!pageDrawArray.length &&
                                    pageDrawArray.map((card: IVideoCard) => (
                                        <VideoCard key={card.id} cardInfo={card} setVideoToShow={setVideoToShow}/>
                                    ))
                                }
							</div>
							<MaterialUIPagination
								style={{marginTop: '20px', alignSelf: 'center', marginBottom: '70px'}}
								count={pageCount}
								page={currentPage}
								onChange={(e, value: number) => {
                                    setCurrentPage(value)
                                    executeScroll(defined(titleRef.current))
                                }}
							/>
						</>
                }
            </div>
        </div>
    )
})