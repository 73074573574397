import React from 'react';
import './Perflo.scss';
import {Grid} from "@mui/material";
import {ProductSubTitle} from "../productSubTitle/ProductSubTitle";
import antiallergik from "../../../../assets/img/productSubTitle/antiallergic.png";
import antiInflammatory from "../../../../assets/img/productSubTitle/antiInflammatory.png";
import antioxidant from "../../../../assets/img/productSubTitle/antioxidant.png";
import moisturizing from "../../../../assets/img/productSubTitle/moisturizing.png";
import perflo from "../../../../assets/img/products/perflodrops.png";
import {BuyButton} from "../../../shared/buttons/buyButton/BuyButton";

export const Perflo = () => {
    return (
        <>
            <Grid container className={'product__mainInfo'}>
                <Grid item lg={4} sm={6} pr={2}>
                    <ProductSubTitle
                        title={'WŁAŚCIWOŚCI ANTYALERGICZNE'}
                        description={'Ekstrakt pozyskany z liści oraz nasion Pachnotki zwyczajnej - łagodzi skutki alergii i łagodzi jej objawy oczne.'}
                        imageLink={antiallergik}
                    />
                    <ProductSubTitle
                        title={'WŁŚCIWOŚCI PRZECIWZAPALNE'}
                        description={'Zawiera polifenole, które posiadają właściwości przeciwzapalne - neutralizują wolne rodniki.'}
                        imageLink={antiInflammatory}
                    />
                </Grid>
                <Grid item lg={4} sm={6} pr={2} className={'product__additionalInfo-hide'}>
                    <img className={'product__imageArea'} src={perflo} alt=""/>
                    <ProductSubTitle
                        title={'WŁAŚCIWOŚCI ANTYOKSYDACYJNE'}
                        description={'Chronią oczy przed stresem oksydacyjnym wywołanym niekorzystnymi czynnikami środowiskowymi.'}
                        imageLink={antioxidant}
                    />
                    <ProductSubTitle
                        title={'NAWILŻANIE'}
                        description={'Nanosomy uzupełniają ewentualne niedobory warstwy lipidowej filmu łzowego, a hypromeloza uzupełnia i stabilizuje warstwę wodną i śluzową.'}
                        imageLink={moisturizing}
                    />
                </Grid>
                <Grid item lg={4} sm={0}>
                    <img src={perflo} alt=""/>
                    <h1 className={'product__additionalInfo-titleUp'}>Perflo Drops<sup>®</sup> - innowacyjne sztuczne łzy z polifenolami
                        o działaniu przeciwzapalnym</h1>
                </Grid>
            </Grid>
            <Grid container className={'product__additionalGrid'}>
                <Grid item xs={0} sm={6} lg={0} >
                    <ProductSubTitle
                        title={'NAWILŻANIE'}
                        description={'Nanosomy uzupełniają ewentualne niedobory warstwy lipidowej filmu łzowego, a hypromeloza uzupełnia i stabilizuje warstwę wodną i śluzową.'}
                        imageLink={moisturizing}
                    />
                </Grid>
            </Grid>
            <Grid container className={'product__additionalInfo'}>
                <h1 className={'product__additionalInfo-title'}>Perflo Drops<sup>®</sup> - innowacyjne sztuczne łzy z polifenolami
                    o działaniu przeciwzapalnym</h1>
                <Grid container className={'product__container'}>
                    <Grid item xl={0}/>
                    <Grid item sm={8} pr={2}>
                        <p>
                            <strong>Perflo Drops<sup>®</sup></strong> to innowacyjne sztuczne łzy, krople oczne na bazie 0,25%
                            kwasu hialuronowego z dodatkiem 0,1% wyciągu z Pachnotki zwyczajnej (Perilla frutescens).
                            Poszczególne składniki preparatu mają działanie nawilżające, natłuszczające, ochronne i
                            regenerujące, ale również przeciwalergiczne i przeciwzapalne.
                        </p>
                        <p>
                            Stosowanie <strong>Perflo Drops<sup>®</sup></strong> jest szczególnie wskazane w zespole suchego oka
                            (ZSO), zwłaszcza spowodowanego dysfunkcją gruczołów Meiboma (MGD), w przebiegu zapalenia
                            spojówek i innych podrażnień, również o podłożu alergicznym.
                        </p>
                        <p>
                            Ekstrakt pozyskany z liści oraz nasion Pachnotki zwyczajnej (Perilla frutescens) zawiera
                            polifenole, które posiadają właściwości antyoksydacyjne (neutralizująwolne rodniki),
                            przeciwalergiczne i przeciwzapalne. Wygaszenie stanu zapalnego pozwala na przerwanie
                            mechanizmu błędnego koła w Zespole Suchego Oka. Kwas hialuronowy to polisacharyd o wysokiej
                            biokompatybilności, który, między innymi dzięki swoim właściwościom wisko elastycznym,
                            znakomicie nawilża i natłuszcza powierzchnię oka.
                        </p>
                        <p>
                            Dzięki powyższym właściwościom krople <strong>Perflo Drops<sup>®</sup></strong> chronią oczy przed
                            stresem oksydacyjnym wywołanym niekorzystnymi czynnikami środowiskowymi, np. dym
                            papierosowy, kurz, działanie klimatyzacji, promienie słoneczne, następstwa noszenia soczewek
                            kontaktowych, światło emitowane przez ekrany komputerów, itp.
                        </p>
                        <p>
                            Stosując krople <strong>Perflo Drops<sup>®</sup></strong> można łagodzić objawy zmęczenia i/lub
                            podrażnienia oczu (zaczerwienienie, uczucie suchości) wywołanego np. po długiej jeździe
                            samochodem, czytaniem, wpatrywaniem się w ekrany (komputera, telefonu), długotrwałym
                            działaniem światła i/lub długotrwałym używaniem soczewek kontaktowych, stanami zapalnymi
                            brzegów powiek czy alergicznym zapaleniem spojówek.
                        </p>
                        <p>
                            Zastosowanie kropli <strong>Perflo Drops<sup>®</sup></strong> przed założeniem soczewek kontaktowych
                            ułatwia ich aplikację i poprawia nawilżenie powierzchni oka. Zakroplenie dodatkowo po
                            założeniu soczewek podnosi komfort ich użytkowania, przeciwdziała uczuciu suchości i innym
                            możliwym komplikacjom.
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Sposób użycia:
                        </h3>
                        <p>
                            Odkręcić zamknięcie minimsa. Delikatnie ścisnąć pojemniczek i zakroplić każde oko 2-3
                            kroplami. Użytkownicy soczewek kontaktowych mogą zaaplikować 1-2 krople bezpośrednio na
                            soczewkę, po czym niezwłocznie ją założyć.
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Skład:
                        </h3>
                        <p>
                            Ekstrakt z Perilla frutescens (o zawartości polifenoli &gt; 2,5%) 0,1%, sól sodowa kwasu
                            hialuronowego 0,25%, NaCl, bezwodny dwuzasadowy fosforan sodu, monohydrat jednozasadowego
                            fosforanu sodu, woda oczyszczona q.s. do 100 ml. Preparat nie zawiera konserwantów.
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Przeciwwskazania:
                        </h3>
                        <p>
                            Nadwrażliwość na którykolwiek ze składników preparatu.
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Ostrzeżenia:
                        </h3>
                        <p>
                            Do użytku zewnętrznego. Przed użyciem należy zapoznać się z ulotką.
                        </p>
                        <h3 className={'product__additionalInfo-subTitle'}>
                            Zawartość:
                        </h3>
                        <p>
                            <strong>Perflo Drops<sup>®</sup></strong> to krople oczne zapakowane w 10 jednorazowych pojemniczków po
                            0,5 ml, tzw. minimsy. Każdy z minimsów po użyciu można ponownie zamknąć, jednak pozostałą
                            zawartość należy zużyć w ciągu 12 godzin od otwarcia pojemniczka. Preparat nie zawiera
                            konserwantów.
                        </p>
                    </Grid>
                    <Grid item sm={4}>
                        <h2 className={'product__additionalInfo-subTitle'}>
                            DZIAŁANIE:
                        </h2>
                        <ul className={'product__additionalInfo-mainList'}>
                            <li>NAWILŻAJĄCE</li>
                            <li>ANTYOKSYDACYJNE</li>
                            <li>ANTYALERGICZNE</li>
                            <li>PRZECIWZAPALNE</li>
                        </ul>
                        <h2 className={'product__additionalInfo-subTitle'}>
                            WSKAZANIA:
                        </h2>
                        <ul className={'product__additionalInfo-mainList'}>
                            <li>Zespół Suchego Oka</li>
                            <ul className={'product__additionalInfo-subList'}>
                                <li>Długotrwałe korzystanie z monitora/smartfona/TV</li>
                                <li>Przebywanie w klimatyzowanych lub ogrzewanych pomieszczeniach</li>
                                <li>Objawy takie jak(*): pieczenie oczy, dyskomfort szczypanie, uczucie piasku pod
                                    powiekami
                                </li>
                            </ul>
                            <li>Zaburzenia powierzchni oka</li>
                            <li>Chemiczne podrażnienia spojówek, np. po basenie</li>
                        </ul>
                        <p>* Po konsultacji z lekarzem i wykluczeniu chorób oczu</p>
                        <p>
                            <strong>Producent:</strong><br/>
                            FB VISION S.p.A. – Via San Giovanni Scafa,<br/>
                            63074 San Benedetto del Tronto (AP)
                        </p>
                    </Grid>
                </Grid>
                <Grid container>
                    <BuyButton
                        linkAllegro={'https://allegro.pl/oferta/perflo-drops-krople-sztuczne-lzy-przeciwzapalne-12091051278'}
                        linkInView={'https://sklep.inviewmedical.pl/pl/p/Perflo-Drops-innowacyjne-sztuczne-lzy-z-polifenolami-o-dzialaniu-przeciwalergicznym-i-przeciwzapalnym/60'}
                    />
                </Grid>
            </Grid>
        </>
    )
}