import {useRootStore} from "../RootStateContext";


export const useMainPageStore = () => {
    const rootStore = useRootStore()

    if (!rootStore?.mainPageStore) {
        throw new Error('mainPage store should be defined')
    }

    return rootStore.mainPageStore
}