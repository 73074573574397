import {useRootStore} from "../RootStateContext";


export const useBlogStore = () => {
    const rootStore = useRootStore();

    if (!rootStore?.blogStore) {
        throw new Error('blog store should be defined')
    }

    return rootStore.blogStore
}