import {makeAutoObservable} from "mobx";
import {MainPageStore} from "./mainPageStore";
import {PoiStore} from "./poiStore";
import {BlogStore} from "./blogStore";
import {KnowledgeStore} from "./knowledgeStore";
import {ProductStore} from "./productStore";

export class RootStore {
    public poiStore = new PoiStore(this);
    public mainPageStore = new MainPageStore(this);
    public blogStore = new BlogStore(this);
    public knowledgeStore = new KnowledgeStore(this);
    public productStore = new ProductStore(this);

    constructor() {
        makeAutoObservable(this, {
            poiStore: false,
            mainPageStore: false,
            blogStore: false,
            knowledgeStore: false,
            productStore: false,
        })
    }
}