import React from 'react';
import './VideoCard.scss';
import {IVideoCard} from "../../../../interfaces";

interface IProps {
    cardInfo: IVideoCard;
    setVideoToShow: (vimeo: string) => void;
}

export const VideoCard: React.FC<IProps> = ({cardInfo, setVideoToShow}) => {
    return (
        <div className={'videoCard'} onClick={()=>setVideoToShow(cardInfo.vimeo)}>
            <img
                className={'videoCard__photo'}
                src={`https://vumbnail.com/${cardInfo.vimeo}.jpg`}
                alt="video thumbnail"
            />
            <h3 className={'videoCard__title'}>{cardInfo.title}</h3>
        </div>
    )
}
