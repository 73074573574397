import {useCallback} from "react";
import {useApiCall} from "./useApiCalls";
import {useMainPageStore} from "../store/hooks";
import {IBlogCard} from "../interfaces";


export const useMainPageApi = () => {
    const {api} = useApiCall()
    const mainPageStore = useMainPageStore()

    const getMainPageInfo = useCallback(async () => {
        const res: IBlogCard [] = await api.apiInstance.get('dashboard/blogs');
        mainPageStore.setBlogBlockCards(res)
    }, [mainPageStore, api.apiInstance])

    return {
        getMainPageInfo
    }
}