import React from 'react';
import './OzodropGel.scss';
import { Grid } from "@mui/material";
import { ProductSubTitle } from "../productSubTitle/ProductSubTitle";
import antiseptic from "../../../../assets/img/productSubTitle/antiseptic.png";
import regeneration from "../../../../assets/img/productSubTitle/regeneration.png";
import moisturizing from "../../../../assets/img/productSubTitle/moisturizing.png";
import dressing from "../../../../assets/img/productSubTitle/dressing.png";
import ozodropGelImage from "../../../../assets/img/products/ozodropGel.png";
import { BuyButton } from "../../../shared/buttons/buyButton/BuyButton";

export const OzodropGel = () => {
  return (
    <>
      <Grid container className={'product__mainInfo'}>
        <Grid item lg={4} sm={6} pr={2}>
          <ProductSubTitle
            title={'ANTYSEPTYKA'}
            descriptionList={[
              'Uwalnianie atomowego tlenu odpowiada za redukcję wszystkich rodzajów patogenów:',
              'Bakterii, w tym wielolekoopornych (92% redukcji CFU!)',
              'Grzybów',
              'Wirusów'
            ]}
            imageLink={antiseptic}
          />
          <ProductSubTitle
            title={'REGENERACJA'}
            description={'Obecność atomowego tlenu wspomaga regenację uszkodzonych tkanek - nabłonka i/lub macierzy rogówki.'}
            imageLink={regeneration}
          />
        </Grid>
        <Grid item lg={4} sm={6} pr={2} className={'product__additionalInfo-hide'}>
          <img className={'product__imageArea'} src={ozodropGelImage} alt="" />
          <ProductSubTitle
            title={'OPATRUNEK'}
            description={'Połączenie LipozonEye<sup>®</sup> oraz termożelu (Kolliphor<sup>®</sup>) pozwala na wytworzenie ściśle przylegającego do powierzchni oka mechanicznego opatrunku przyspieszającego fizjologiczne procesy odbudowy.'}
            imageLink={dressing}
          />
          <ProductSubTitle
            title={'NAWILŻANIE'}
            description={'Nanosomy uzupełniają ewentualne niedobory warstwy lipidowej filmu łzowego, a hypromeloza uzupełnia i stabilizuje warstwę wodną i śluzową.'}
            imageLink={moisturizing}
          />
        </Grid>
        <Grid item lg={4} sm={0}>
          <img src={ozodropGelImage} alt="" />
          <h1 className={'product__additionalInfo-titleUp'}>OZODROP GEL<sup>®</sup>– ANTYSEPTYCZNY ŻEL OCZNY Z OZONEM 4W1</h1>
        </Grid>
      </Grid>
      <Grid container className={'product__additionalGrid'}>
        <Grid item xs={0} sm={6} lg={0} >
          <ProductSubTitle
            title={'NAWILŻANIE'}
            description={'Nanosomy uzupełniają ewentualne niedobory warstwy lipidowej filmu łzowego, a hypromeloza uzupełnia i stabilizuje warstwę wodną i śluzową.'}
            imageLink={moisturizing}
          />
        </Grid>
      </Grid>
      <Grid container className={'product__additionalInfo'}>
        <h1 className={'product__additionalInfo-title'}>OZODROP GEL<sup>®</sup>– ANTYSEPTYCZNY ŻEL OCZNY Z OZONEM 4W1</h1>
        <Grid container className={'product__container'}>
          <Grid item xl={0} />
          <Grid item sm={8} pr={2}>
            <p>
              ANTYSEPTYCZNE, WSPOMAGAJĄCE REGENERACJĘ, NAWILŻAJĄCE i OPATRUNKOWE krople do oczu 4 w 1 w
              formie termożelu z ozonem (formuła LipozonEye<sup>®</sup>). <strong>OZODROP GEL<sup>®</sup></strong> to krople
              oczne bez konserwantów DLA DZIECI ORAZ DOROSŁYCH do stosowania w leczeniu i profilaktyce
              infekcji o dowolnej etiologii. Szczególnie rekomendowane po urazach oka i przy uszkodzeniu
              powierzchni oka. <strong>OZODROP GEL<sup>®</sup></strong> zachowuje wszystkie unikalne właściwości
              kropli ocznych OZODROP<sup>®</sup> (ANTYSEPTYKA-REGENERACJA-NAWILŻENIE), a dodatkowo dzięki
              zastosowanej formule termożelu tworzy mechaniczny OPATRUNEK na powierzchni oka, który w
              przypadku jej uszkodzenia zapewnia NATYCHMIASTOWĄ ULGĘ OD BÓLU oraz wydłużenie działania
              ANTYSEPTYCZNEGO, WSPOMAGAJĄCEGO REGENERACJĘ ORAZ NAWILŻAJĄCEGO. <strong>OZODROP
                GEL<sup>®</sup></strong> to absolutny przełom w zapobieganiu i leczeniu infekcji ocznych oraz
              bezpieczna dla oka alternatywa dla antybiotykoterapii.
            </p>
            <h3 className={'product__additionalInfo-subTitle'}>
              Sposób użycia:
            </h3>
            <p>
              Zaaplikuj jedną lub dwie krople do każdego oka 3-4 razy dziennie lub zgodnie z zaleceniami
              lekarza. Produkt można stosować podczas noszenia soczewek kontaktowych.
            </p>
            <h3 className={'product__additionalInfo-subTitle'}>
              Skład:
            </h3>
            <p>
              <strong>LipozonEye<sup>®</sup></strong> (ozonowany olej z nasion słonecznika, fosfolipidy sojowe),
              hydroksyetyloceluloza,
              kwas borowy, tetraboran sodu, wersenian disodowy, PHMB, kolliphor P407 i woda dejonizowana.
            </p>
            <h3 className={'product__additionalInfo-subTitle'}>
              Przeciwwskazania:
            </h3>
            <p>
              Nadwrażliwosć na którykolwiek ze składników preparatu.
            </p>
            <h3 className={'product__additionalInfo-subTitle'}>
              Ostrzeżenia:
            </h3>
            <p>
              Do użytku zewnętrznego. Przed użyciem należy zapoznać się z ulotką.
            </p>
            <h3 className={'product__additionalInfo-subTitle'}>
              Zawartość:
            </h3>
            <p>
              Opakowanie zawiera butelkę zawierającą 6ml kropli do oczu.
            </p>
          </Grid>
          <Grid item sm={4}>
            <h2 className={'product__additionalInfo-subTitle'}>
              DZIAŁANIE:
            </h2>
            <ul className={'product__additionalInfo-mainList'}>
              <li>ANTYSEPTYCZNE</li>
              <ul className={'product__additionalInfo-subList'}>
                <li>Przeciwbakteryjne</li>
                <li>Przeciwwirusowe</li>
                <li>Przeciwgrzybicze</li>
              </ul>
              <li>WSPOMAGAJĄCE REGENERACJĘ</li>
              <li>NAWILŻAJĄCE</li>
              <li>OCHRONNE i PRZECIWBÓLOWE dzięki wytworzeniu mechanicznego opatrunku na powierzchni oka</li>
            </ul>
            <h2 className={'product__additionalInfo-subTitle'}>
              WSKAZANIA:
            </h2>
            <ul className={'product__additionalInfo-mainList'}>
              <li>Po urazach oka</li>
              <li>Po usunięciuciała obcego</li>
              <li>Po oparzeniach termicznych i chemicznycho</li>
              <li>„Czerwone oko”</li>
              <ul className={'product__additionalInfo-subList'}>
                <li>Zapalenia spojówek niezależnie od etiologii</li>
                <li>Zapalenia rogówki niezależnie od etiologii</li>
              </ul>
              <li>Ciężki Zespół Suchego Oka</li>
              <li>Profilaktycznie w zapobieganiu infekcji u użytkowników soczewek kontaktowych</li>
            </ul>
            <p>
              <strong>Producent:</strong><br />
              FB VISION S.p.A. – Via San Giovanni Scafa,<br />
              63074 San Benedetto del Tronto (AP)
            </p>
          </Grid>
        </Grid>
        <Grid container>
          <BuyButton
            linkAllegro={'https://allegro.pl/oferta/ozodrop-gel-antyseptyczny-zel-oczny-z-ozonem-4w1-12091047324'}
            linkInView={'https://sklep.inviewmedical.pl/pl/p/OZODROP-GEL-ANTYSEPTYCZNY-ZEL-OCZNY-Z-OZONEM-4W1-/72'}
          />
        </Grid>
      </Grid>
    </>
  )
}