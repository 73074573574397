import React, {useEffect} from 'react';
import './VideoArea.scss';
import Vimeo from "@u-wave/react-vimeo";

interface IProps {
    videoToShow: string;
    setVideoToShow: (vimeo: string | null) => void;
}

export const VideoArea: React.FC<IProps> = ({setVideoToShow, videoToShow}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={'videoArea'}>
            <button className={'headerButton'} onClick={()=>setVideoToShow(null)}>Powrót</button>
            <Vimeo
                className={'videoArea-video'}
                video={videoToShow}
                autoplay
            />
        </div>
    )
}