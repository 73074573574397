import {makeAutoObservable} from "mobx";
import {RootStore} from "./rootStore";
import {IBlogCard, ISingleBlog} from "../interfaces";

export class BlogStore {

    blogList: IBlogCard[] = [];

    singleBlogInfo: ISingleBlog | null = null;

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this)
    }

    setBlogList(blogList: IBlogCard[]){
        this.blogList = [...blogList]
    }

    setSingleBlogInfo(singleBlogInfo: ISingleBlog) {
        this.singleBlogInfo = {...singleBlogInfo}
    }

}