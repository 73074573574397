import {RootStore} from "./rootStore";
import {makeAutoObservable} from "mobx";
import {IBlogCard} from "../interfaces";


export class MainPageStore {

    blogBlockCards: IBlogCard[] = [];


    constructor(private rootStore: RootStore) {
        makeAutoObservable(this)
    }

    setBlogBlockCards(blogBlockCards: IBlogCard[]) {
        this.blogBlockCards = [...blogBlockCards]
    }
}