import React, {FC, LegacyRef} from 'react';
import './BlockTitle.scss';
import eye85x70 from '../../../assets/img/eye85x70.png';

interface IProps {
    title: string;
    subTitle?: string;
    titleRef?: LegacyRef<HTMLHeadingElement>;
}

export const BlockTitle: FC<IProps> = ({title, titleRef, subTitle}) => {
    return (
        <div className={'blockTitle'} >
            <div className={'blockTitle__image'}>
                <img  src={eye85x70} alt="eye"/>
            </div>
            <h2 className={'blockTitle__text'} style={{alignSelf: subTitle ? 'stretch' : 'flex-end'}} ref={titleRef}>{title} {subTitle && <span>{subTitle}</span>}</h2>
        </div>
    )
}