import React from 'react';
import './Ozolid.scss';
import { Grid } from "@mui/material";
import { ProductSubTitle } from "../productSubTitle/ProductSubTitle";
import antiseptic from "../../../../assets/img/productSubTitle/antiseptic.png";
import regeneration from "../../../../assets/img/productSubTitle/regeneration.png";
import moisturizing from "../../../../assets/img/productSubTitle/moisturizing.png";
import ozolidImage from "../../../../assets/img/products/ozolid.png";
import { BuyButton } from "../../../shared/buttons/buyButton/BuyButton";

export const Ozolid = () => {
  return (
    <>
      <Grid container className={'product__mainInfo'}>
        <Grid item lg={4} sm={6} pr={2}>
          <ProductSubTitle
            title={'ANTYSEPTYKA'}
            descriptionList={[
              'Uwalnianie atomowego tlenu odpowiada za redukcję wszystkich rodzajów patogenów:',
              'Bakterii, w tym wielolekoopornych (92% redukcji CFU!)',
              'Grzybów',
              'Wirusów'
            ]}
            imageLink={antiseptic}
          />
          <ProductSubTitle
            title={'REGENERACJA'}
            description={'Obecność atomowego tlenu wspomaga regenację uszkodzonych tkanek - nabłonka i/lub macierzy rogówki.'}
            imageLink={regeneration}
          />
        </Grid>
        <Grid item lg={4} sm={6} pr={2}>
          <img className={'product__imageArea'} src={ozolidImage} alt="" />
          <ProductSubTitle
            title={'NAWILŻANIE'}
            description={'Nanosomy uzupełniają ewentualne niedobory warstwy lipidowej filmu łzowego, a hypromeloza uzupełnia i stabilizuje warstwę wodną i śluzową.'}
            imageLink={moisturizing}
          />
        </Grid>
        <Grid item lg={4} sm={0}>
          <img src={ozolidImage} alt="" />
          <h1 className={'product__additionalInfo-titleUp'}>Ozolid<sup>®</sup> ANTYSEPTYCZNE CHUSTECZKI Z OZONEM 3W1</h1>
        </Grid>
      </Grid>
      <Grid container className={'product__additionalInfo'}>
        <h1 className={'product__additionalInfo-title'}>Ozolid<sup>®</sup> ANTYSEPTYCZNE CHUSTECZKI Z OZONEM 3W1</h1>
        <Grid container className={'product__container'}>
          <Grid item xl={0} />
          <Grid item sm={8} pr={2}>
            <p>
              Jednorazowe kompresy okulistyczne składającesię z gazy nasączonej roztworem opartym na
              ozonowanym oleju roślinnym w nonosomach <strong>(LipozonEye<sup>®</sup>). Ozolid<sup>®</sup></strong> został
              opracowany specjalnie
              w celu mechanicznego usuwania z rzęs oraz powiek wydzielin oka powstających w wyniku stanów
              zapalnych, zapewniając jednoczesną dezynfekcję powiek i nawilżenie wydzielin oczyszczanych
              powierzchni. <strong>Ozolid<sup>®</sup></strong> może być z powodzeniem stosowany do codziennej
              higieny, w celu
              trzymania prawidłowegostanu okolic oczu i twarzy.
            </p>
            <h3 className={'product__additionalInfo-subTitle'}>
              Sposób użycia:
            </h3>
            <p>
              Po dokładnym umyciu rąk, otwórz saszetkę i wyjmij kompres z gazy. Delikatnie przetrzyj
              gaząokolicę powieki, wykonując kilka drobnych okrężnych ruchów, aby umożliwić usunięcie
              wydzielin oczu i wszelkich innych osadów (kurz, brud itp.). W przypadku trudnych do
              usunięcia wydzielin lub stanów zapalnych dotyczących brzegów powiek (zapalenie brzegów
              powiek, zapalenie brzegów powiek i spojówek, jęczmień) zaleca się podgrzewanie zamkniętej
              saszetki przez kilka minut w ciepłej wodzie (40 °C), a następnie stosowanie gazy jako
              ciepłego kompresu. W przypadku obrzęku powiek zaleca się schłodzenie zamkniętej saszetki w
              lodówce przez co najmniej godzinę, a następnie nałożenie gaz yna zamknięte powieki na kilka
              minut.
            </p>
            <h3 className={'product__additionalInfo-subTitle'}>
              Skład:
            </h3>
            <p>
              <strong>LipozonEye<sup>®</sup></strong> (ozonowany olej z nasion słonecznika, fosfolipidy sojowe),
              hydroksypropylometyloceluloza, kwas borowy, tetraboran sodu, sól disodowa EDTA, PHMB, woda
              demineralizowana.
            </p>
            <h3 className={'product__additionalInfo-subTitle'}>
              Przeciwwskazania:
            </h3>
            <p>
              Nadwrażliwość na którykolwiek ze składników preparatu.
            </p>
            <h3 className={'product__additionalInfo-subTitle'}>
              Ostrzeżenia:
            </h3>
            <p>
              Do użytku zewnętrznego. Przed użyciem należy zapoznać się z ulotką.
            </p>
          </Grid>
          <Grid item sm={4}>
            <h2 className={'product__additionalInfo-subTitle'}>
              DZIAŁANIE:
            </h2>
            <ul className={'product__additionalInfo-mainList'}>
              <li>ANTYSEPTYCZNE</li>
              <ul className={'product__additionalInfo-subList'}>
                <li>Przeciwbakteryjne</li>
                <li>Przeciwwirusowe</li>
                <li>Przeciwgrzybicze</li>
              </ul>
              <li>WSPOMAGAJĄCE REGENERACJĘ</li>
              <li>NAWILŻAJĄCE</li>
            </ul>
            <h2 className={'product__additionalInfo-subTitle'}>
              WSKAZANIA:
            </h2>
            <ul className={'product__additionalInfo-mainList'}>
              <li>Zespół Suchego Oka</li>
              <li>Nużyca powiek</li>
              <li>Zapalenie brzegów powiek</li>
              <li>„Zmęczone” oczy</li>
              <li>Trądzik pospolity i różowaty</li>
              <li>Po zabiegach medycyny estetycznej</li>
              <li>Znakomite także do demakijażu</li>
            </ul>

            <p>
              <strong>Producent:</strong><br />
              FB VISION S.p.A. – Via San Giovanni Scafa,<br />
              63074 San Benedetto del Tronto (AP)
            </p>
          </Grid>
        </Grid>
        <Grid container>
          <BuyButton
            linkAllegro={'https://allegro.pl/oferta/ozolid-antyseptyczne-chusteczki-z-ozonem-3w1-12091050271'}
            linkInView={'https://sklep.inviewmedical.pl/pl/p/Ozolid-ANTYSEPTYCZNE-CHUSTECZKI-Z-OZONEM-3W1/52'}
          />
        </Grid>
      </Grid>
    </>
  )
}