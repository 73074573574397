import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {useProductApi} from "../../../../apiHooks";
import {useProductStore} from "../../../../store/hooks";
import {observer} from "mobx-react-lite";
import { Grid } from "@mui/material";
import {BuyButton} from "../../../shared/buttons/buyButton/BuyButton";
import {convertIframe,  validateString} from "../../../../utils";
import "./CustomProduct.scss";

export const CustomProduct = observer(() => {
    const params = useParams()
    const navigate  = useNavigate()

    const {getSingleProduct} = useProductApi()
    const productStore = useProductStore()
    const { product, isError } = productStore

    useEffect(()=>{
        if(params?.id){
            const id = Number.parseInt(params.id)
            if(typeof id === 'number'){
                void getSingleProduct(id)
            }
        }
    }, [params.id, getSingleProduct])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if(isError){
            navigate('/404')
        }
    }, [isError, navigate])

    const isProductLoaded = !!product
    const isFilm = isProductLoaded ? product.film  !== null : false

    return <div>
        {
            isProductLoaded &&
                <>
                    <div className={'product__custom__topContainer'}>
                        <img className={'product__custom__imageArea'} src={product.image} alt={product.title}/>
                    </div>

                    <Grid container className={'product__additionalInfo'} >
                        <div className={'product__custom__additionalInfo-title'} dangerouslySetInnerHTML={{__html: validateString(product.title)}}/>
                        <Grid container className={'product__custom__additionalInfo-text'}>
                            <Grid item xs={12} lg={6} pr={2} >
                                <p dangerouslySetInnerHTML={{__html: validateString(product.leftDescription)}}></p>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <p dangerouslySetInnerHTML={{__html: validateString(product.rightDescription)}}></p>
                            </Grid>
                        </Grid>
                        {isFilm &&
                           <div className={'product__custom_additionalInfo-video_container'} dangerouslySetInnerHTML={{__html: convertIframe(product.film)}} />
                        }
                        <Grid container>
                            <BuyButton
                                linkAllegro={product.linkAllegro}
                                linkInView={product.linkInview}
                            />
                        </Grid>
                    </Grid>
                </>
        }
    </div>
});