import React from 'react'
import {BlockTitle} from "../../../shared/BlockTitle/BlockTitle";
import {Slide} from "./Slide";

import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import './SliderBlock.scss';

import ozodropImage from '../../../../assets/img/products/ozodrop.png';
import ozodropGelImage from '../../../../assets/img/products/ozodropGel.png';
import perfloImage from '../../../../assets/img/products/perflodrops.png';
import macuShieldImage from '../../../../assets/img/products/macushield.png';

import dryEye from '../../../../assets/img/animation/dryEye.png';
import amd from '../../../../assets/img/animation/amd.png';
import redEye from '../../../../assets/img/animation/redEye.png';
import tearEye from '../../../../assets/img/animation/tearEye.png';

export const SliderBlock = () => {
    return (
        <div className={'mainSlider'}>
            <div className={'app__container'}>
                <BlockTitle title={'Oczy'} subTitle={'- jak dbać?'}/>
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                    0: {
                       touchRatio: 1
                    },
                    601: {
                        touchRatio: 0
                    }}}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <Slide
                            title={'OZODROP<sup>®</sup> – ANTYSEPTYCZNE KROPLE OCZNE Z OZONEM 3W1'}
                            link={'/productList/1'}
                            linkAllegro={'https://allegro.pl/oferta/ozodrop-antyseptyczne-krople-oczne-z-ozonem-3w1-12058258389'}
                            linkInView={'https://sklep.inviewmedical.pl/pl/p/OZODROP-ANTYSEPTYCZNE-KROPLE-OCZNE-Z-OZONEM-3W1-/51'}
                            drugImage={ozodropImage}
                            animatedEyeImage={dryEye}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Slide
                            title={'OZODROP GEL<sup>®</sup>– ANTYSEPTYCZNY ŻEL OCZNY Z OZONEM 4W1'}
                            link={'/productList/4'}
                            linkAllegro={'https://allegro.pl/oferta/ozodrop-gel-antyseptyczny-zel-oczny-z-ozonem-4w1-12091047324'}
                            linkInView={'https://sklep.inviewmedical.pl/pl/p/OZODROP-GEL-ANTYSEPTYCZNY-ZEL-OCZNY-Z-OZONEM-4W1-/72'}
                            drugImage={ozodropGelImage}
                            animatedEyeImage={redEye}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Slide
                            title={'Perflo Drops<sup>®</sup> - innowacyjne sztuczne łzy z polifenolami o działaniu przeciwzapalnym'}
                            link={'/productList/3'}
                            linkAllegro={'https://allegro.pl/oferta/perflo-drops-krople-sztuczne-lzy-przeciwzapalne-12091051278'}
                            linkInView={'https://sklep.inviewmedical.pl/pl/p/Perflo-Drops-innowacyjne-sztuczne-lzy-z-polifenolami-o-dzialaniu-przeciwalergicznym-i-przeciwzapalnym/60'}
                            drugImage={perfloImage}
                            animatedEyeImage={tearEye}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Slide
                            title={'MacuShield<sup>®</sup> GOLD 30 - Luteina-Mezozeaksantyna-Zeaksantyna (LMZ<sup>®</sup>) + witaminy + minerały'}
                            link={'/productList/6'}
                            linkAllegro={'https://allegro.pl/oferta/macushield-gold-30-szt-zwyrodnienia-plamki-zoltej-12091052647'}
                            linkInView={'https://sklep.inviewmedical.pl/pl/p/MacuShield-GOLD-30-Luteina-Mezozeaksantyna-Zeaksantyna-LMZ-witaminy-mineraly/36'}
                            drugImage={macuShieldImage}
                            animatedEyeImage={amd}
                        />
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}