import {makeAutoObservable} from "mobx";
import {RootStore} from "./rootStore";
import {IProductInfo} from "../interfaces";
import {ISingleProduct} from "../interfaces/singleProduct";

const initialProductsList: IProductInfo[] = [
    {
        id: "c1",
        name: 'Ozodrop<sup>®</sup>',
        title: 'antyseptyczne krople oczne z ozonem 3w1',
        image: './assets/img/products/ozodrop.png',
    },
    {
        id: "c2",
        name: 'BlefOX<sup>®</sup>',
        title: 'antyseptyczna pianka z ozonem 5w1',
        image: './assets/img/products/blefox.png',
    },
    {
        id: "c3",
        name: 'Perflo Drops<sup>®</sup>',
        title: 'sztuczne łzy o działaniu przeciwzapalnym',
        image: './assets/img/products/perflodrops.png',
    },
    {
        id: "c4",
        name: 'Ozodrop Gel<sup>®</sup>',
        title: 'antyseptyczny żel oczny z ozonem 4w1',
        image: './assets/img/products/ozodropGel.png',
    },
    {
        id: "c5",
        name: 'Ozolid<sup>®</sup>',
        title: 'antyseptyczne chusteczki z ozonem 3w1 ',
        image: './assets/img/products/ozolid.png',
    },
    {
        id: "c6",
        name: 'MacuShield<sup>®</sup> GOLD 30',
        title: '(LMZ<sup>®</sup>) + witaminy + minerały',
        image: './assets/img/products/macushield.png',
    },
]

export class ProductStore {
    productList: IProductInfo[] = [...initialProductsList];
    product : ISingleProduct | null = null;
    isError : boolean = false;

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this)
    }

    setProductList(productList: IProductInfo[]){
        this.productList = [...initialProductsList, ...productList]
    }

    setSingleProductInfo(product : ISingleProduct){
        if(product){
            this.isError = false
            this.product = product
        }
        else
            this.isError = true
    }


    get initialProductsLength() {
        return initialProductsList.length
    }

    get sliderProductList() {
        return this.productList.slice(0, this.initialProductsLength)
    }
}

