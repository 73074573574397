import React, {FC, ReactNode} from 'react';
import './HeaderButton.scss';
import {NavLink} from 'react-router-dom';

interface IProps {
    link: string;
    title?: string;
    children?: ReactNode;
    setMenu?: () => void | undefined
}

export const HeaderButton: FC<IProps> = ({link, title, children, setMenu}) => {
    return (
        <li onClick={setMenu}>
            <NavLink
                className={({isActive}) => `headerButton ${isActive && 'headerButton__activeLink'}`}
                to={link}
                end
            >
                {children}
                {title}
            </NavLink>
        </li>
    )
}