import React from 'react';
import './KnowledgeBlockLinkButton.scss';
import {useNavigate} from "react-router-dom";
import articleImg from '../../../../assets/img/articles.png';
import videoImg from '../../../../assets/img/video.png';
import questionImg from '../../../../assets/img/questions.png';
import arrowRight from "../../../../assets/img/arrowRight10x16.png";

export const KnowledgeBlockLinkButton: React.FC<{
    link: string,
    title: string,
    img: number
}> = ({
          link,
          img,
          title
      }) => {

    const navigate = useNavigate()

    return (
        <div
            className={'knowledgeBlockLinkButton'}
            onClick={()=>navigate(link)}
        >
            <img src={img === 1 ? articleImg : img === 2 ? videoImg : questionImg} alt="card"/>
            <p className={'knowledgeBlockLinkButton__fakeLink'}><span>{title}</span> <img src={arrowRight} alt="arrow right"/></p>
        </div>
    )
}