import React, {LegacyRef} from 'react';
import './Footer.scss';
import eye from '../../../assets/img/eye14x12.png';

interface IProps {
    contactRef: LegacyRef<HTMLHeadingElement>;
}

export const Footer: React.FC<IProps> = ({contactRef}) => {

    return (
        <div className={'footer'}>
                <div className={'footer__container'}>
                <div className={'footer__main'}>
                    <h4 ref={contactRef}>Kontakt</h4>
                    <p><span>InView Medical</span></p>
                    <p>Pl. Powstańców Śląskich 17A/222, 53-329 Wrocław</p>
                    <p>telefon kontaktowy: <a href="tel:71 787 75 45">71 787 75 45</a></p>
                    <p>e-mail: <a href="mailto:info@inviewmedical.pl" target='_blank'
                                  rel='noreferrer'>info@inviewmedical.pl</a></p>
                    <p><a href="https://www.inviewmedical.pl/" target='_blank' rel='noreferrer'>www.inviewmedical.pl</a>
                    </p>
                </div>
                    <div className={'footer__rules'}>
                        <div>
                            Design & Powered by Softwebo
                        </div>
                        <div>
                            <a
                                href="https://admin.wzrokok.pl/api/file/privacy-policy"
                                target='_blank'
                                rel='noreferrer'
                            >
                                Polityka prywatności
                            </a>
                            <img src={eye} alt=""/>
                            <a
                                href="https://admin.wzrokok.pl/api/file/regulamin"
                                target='_blank'
                                rel='noreferrer'
                            >
                                Regulamin
                            </a>
                        </div>
                    </div>
                </div>
        </div>
    )
}