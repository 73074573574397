import {useRootStore} from "../RootStateContext";


export const useProductStore = () => {
    const rootStore = useRootStore();

    if (!rootStore?.productStore) {
        throw new Error('product store should be defined')
    }

    return rootStore.productStore;
}