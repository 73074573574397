import React, {useState} from 'react';
import './DropDownMenu.scss';
import {HeaderButton} from "../buttons/headerButton/HeaderButton";

interface IProps {
    title: string;
}

export const DropDownMenu: React.FC<IProps> = ({title}) => {
    const [isDropDownActive, setIsDropDownActive] = useState(false)
    const openMenuHandler = () => setIsDropDownActive(true)
    const closeMenuHandler = () => setIsDropDownActive(false)
    return (
        <li className={'dropDownMenu'} onMouseLeave={closeMenuHandler}>
            <button className={'headerButton'} onMouseEnter={openMenuHandler}>{title}</button>
            <ul className={`dropDownMenu__dropZone ${isDropDownActive ? 'dropDownMenu__dropZone-show' : ''}`}>
                <HeaderButton setMenu={closeMenuHandler} link={'/knowledge/files'} title={'Artykuły'}/>
                <HeaderButton setMenu={closeMenuHandler} link={'/knowledge/video'} title={'Filmy'}/>
                <HeaderButton setMenu={closeMenuHandler} link={'/knowledge/qa'} title={'Q&A'}/>
            </ul>
        </li>
    )
}