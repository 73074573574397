import React, {useEffect} from 'react';
import './MainPage.scss';
import {SliderBlock} from "./sliderBlock/SliderBlock";
import {BlogBlock} from "./blogBlock/BlogBlock";
import {KnowledgeBlock} from "./knowledgeBlock/KnowledgeBlock";
import {ProductsBlock} from "./productsBlock/ProductsBlock";
import { useMainPageApi } from '../../../apiHooks';

export const MainPage = () => {

    const {getMainPageInfo} = useMainPageApi();

    useEffect(()=>{
        void getMainPageInfo()
    }, [getMainPageInfo])

    return (
        <div className={'mainPage'}>
            <SliderBlock/>
            <BlogBlock/>
            <KnowledgeBlock/>
            <ProductsBlock/>
        </div>
    )
}