import React, {useEffect} from 'react';
import './Page404.scss';
import eye from '../../../assets/img/eye90x90.png';

export const Page404 = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={'page404 app__blockHeight'}>
            <div className={'app__container'}>
                <img src={eye} alt="eye"/>
                <h3>404</h3>
                <h5>STRONA NIEDOSTĘPNA</h5>
                <p>Niestety podany adres strony nie jest dostępny.</p>
            </div>
        </div>
    )
}