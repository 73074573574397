import React from 'react';
import './Header.scss';
import {NavBar} from "../navBar/NavBar";

interface IProps {
    contactScrollHandler: () => void
}

export const Header: React.FC<IProps> = ({contactScrollHandler}) => {
    return (
        <header className={'header'}>
            <div className={'app__container'}>
                <NavBar contactScrollHandler={contactScrollHandler} />
            </div>
        </header>
    )
}