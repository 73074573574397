import React, {useEffect, useRef, useState} from 'react';
import {BlockTitle} from "../../shared/BlockTitle/BlockTitle";
import {useProductStore} from "../../../store/hooks";
import {useProductApi} from "../../../apiHooks";

import {IProductInfo} from "../../../interfaces";
import { ProductCard } from '../../shared/cards/productCard/ProductCard';
import { observer } from 'mobx-react-lite';
import {MaterialUIPagination} from "../../shared/pagination/MaterialUIPagination";
import './ProductList.scss';
import {defined, executeScroll} from "../../../utils";

export const ProductList = observer(() => {
    const {getProductList} = useProductApi()

    const productStore = useProductStore();
    const { productList} = productStore

    const [drawArray, setDrawArray] = useState<IProductInfo[]>([]);
    const [pageDrawArray, setPageDrawArray] = useState<IProductInfo[]>([]);
    const [pageCount, setPageCount] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const cardsPerPage = 6;

    const titleRef = useRef<HTMLHeadingElement>(null)


    useEffect(() => {
        void getProductList();
    }, [getProductList])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (!!productList.length) {
            setDrawArray([...productList])
            setPageCount(Math.ceil(productList.length / cardsPerPage))
        }
    }, [productList])

    useEffect(() => {
        setPageDrawArray([...drawArray.filter((card, index) => (index >= (currentPage - 1) * cardsPerPage) && (index < currentPage * cardsPerPage))])
    }, [drawArray, currentPage, cardsPerPage])

    return (
        <div className={'productList app__blockHeight'}>
            <div className={'app__container'}>
                <BlockTitle title={'Produkty'} titleRef={titleRef}/>
                    {
                        !!drawArray.length &&
                        <>
                            <div className={'productList__cardsArea'}>
                                {
                                    !!pageDrawArray.length &&
                                        pageDrawArray.map((card: IProductInfo) => <ProductCard key={card.id} cardInfo={card}/>)
                                }
                            </div>
                                <MaterialUIPagination
                                    style={{marginTop: '30px', marginBottom: '70px'}}
                                    count={pageCount}
                                    page={currentPage}
                                    onChange={(e, value: number) => {
                                    setCurrentPage(value)
                                    executeScroll(defined(titleRef.current))
                            }}
                        />
                     </>
                    }
            </div>
        </div>
    )
})